import React from 'react';

import loader from '../../assets/loader/loader.gif';

import './styles.scss'

export const LoaderComponent = () => {

  return (
    <div className="loader-wrapper">
      <img src={loader} alt="Loading" className="loader-img"/>
    </div>
  )
}
