import React, {useEffect, useState} from 'react';
import { LessonsList } from '../lessonsList';
import axios from 'axios';
import { API_URL } from '../constants';
import { LoaderComponent } from '../loader';

import './styles.scss';

export const FavoriteLessons = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [isLoading, setIsLoading] = useState(false);
  const [lessonData, setLessonData] = useState([]);

  const getFavoriteLessons = async (updatedFavorites) => {
    const followData = updatedFavorites ? updatedFavorites : user.favorites;
    try {
      setIsLoading(true);
      let res = await axios.post(
        `${API_URL}/lessons-follow`,
        {
          follow: followData
        });
      setLessonData(res.data);
    } catch (err) {
      console.log('Get user favorite lessons error:', err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getFavoriteLessons();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div>
      <LessonsList lessonsDate={lessonData} loading={setIsLoading} updateLessons={getFavoriteLessons}/>
    </div>
  );
};
