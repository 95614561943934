import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import firebase from 'firebase';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../components/constants';
import { LoaderComponent } from '../../components/loader';
import { TOPICS } from '../../components/constants';

import './styles.scss';

const googleProvider = new firebase.auth.GoogleAuthProvider();

export const Welcome = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const sliderContent = [
    {
      img: require('../../assets/img/boy_dream.jpg'),
      text: `Выбирайте уроки из уже имеющихся или же закажите нужную вам тему урока и удобное время. Создайте подходящее лично вам расписание. Знакомьтесь с новыми друзьями.`
    },
    {
      img: require('../../assets/img/girl_ipad.jpg'),
      text: `Учитесь в любой точке мира, в любое удобное для вас время и в любом удобном для вас месте. Все что вам 
      понадобится, это гаджет для подключения и интернет`
    },
    {
      img: require('../../assets/img/teacher_man.jpg'),
      text: 'Познакомьтесь с нашими учителями. Это специалисты в своей области, влюбленные в свое дело, умеющие найти подход к ребенку, заинтересовать и увлечь его. С ними не соскучишься!'
    }
  ];

  const login = async () => {
    try {
      setIsLoading(true);
      const { user } = await firebase.auth().signInWithPopup(googleProvider);

      const result = await axios.post(`${API_URL}/login`, {
        email: user.email,
        displayName: user.displayName,
        fireId: user.uid,
        avatar: {
          url: user.photoURL,
        },
      });

      localStorage.setItem('user', JSON.stringify(result.data));
      setUserData(user);
      history.push(!result.data.type ? `/person-type/${result.data._id}` : '/profile');
    } catch (error) {
      alert(`Login error.` + (error.message ? ` ${error.message}` : ''));
    } finally {
      setIsLoading(false);
    }
  };

  const goToProfile = () => {
    history.push(user.type ? '/profile' : `/person-type/${user._id}`);
  };

  const handleSubjectClick = subject => {
    history.push(`lessons?subject=${subject}`)
  };

  useEffect(() => {
    setUserData(user)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <LoaderComponent />
  }
  return (
    <div className="welcome-main-wrapper">
      <div className="welcome-main-container">
        <img src={require('../../assets/img/first_page.jpg')} alt='logo-girl' className="logo-girl"/>
        <div className="welcome-header-container">
          <div className="welcome-header-logo-container">
            <img src={require('../../assets/img/logo_white.png')} className="welcome-header-logo" alt="logo"/>
          </div>
          <div className="welcome-header-buttons-container">
            <Button variant="outlined" onClick={() => history.push('/lessons')}>Найти Урок</Button>
            {!userData ? <Button variant="outlined" onClick={login}>Войти</Button>
              : <Button variant="outlined" onClick={goToProfile}>Мой&nbsp;профиль</Button>}
          </div>
        </div>
        <div className="welcome-content-container">
          <div className="courses-description-container">
            <p className="courses-description-header">
              Онлайн уроки с репетиторами
            </p>
            <p className="courses-description-content">
              Найди свой урок и получи знания от талантливых учителей со всего мира. Стоимость наших уроков от 7$. Регистрация бесплатна.
            </p>
            <Button className="register-button" onClick={login}>ЗАРЕГИСТРИРОВАТЬСЯ</Button>
          </div>
          <div className="welcome-info-slider-container">
            <p className="welcome-info-slider-header">
              Создайте свое собственное пространство для образования вашего ребенка
            </p>
            <div className="welcome-info-slider">
              {
                sliderContent.map((slide, i) => (
                  <div className="welcome-info-slide" key={i}>
                    <img src={slide.img} className="welcome-info-slide-img" alt="" />
                    <p className="welcome-info-slide-text">{slide.text}</p>
                  </div>
                ))
              }
            </div>
          </div>
          <div className="welcomу-tagline-container">
            <p className="welcome-tagline-header">
              Хотите преподовать?
            </p>
            <div className="welcome-tagliner-description">
              Lessons Lab - это возможность заниматься любимым делом в удобное для вас время из любой точки мира.<br/>
              Вы занимаетесь только тем, что любите больше всего - обучением.<br/>
              Все остальное мы берем на себя!<br/>
              Мы предоставляем удобную платформу для ваших уроков, надежную систему оплаты, рекламу ваших уроков и поиск новых  учеников<br/>
              <Button className="register-button" onClick={login}>ЗАРЕГИСТРИРОВАТЬСЯ</Button>
            </div>
          </div>
          <div className="welcome-subjects-section">
            <p className="welcome-subjects-section-header">Темы уроков</p>
            <div className="welcome-subjects-wrapper">
              {
                TOPICS.map((subject) => (
                  <div className="welcome-subjects-container" key={subject.value} onClick={() => handleSubjectClick(subject.text)}>
                    {subject.icon} &nbsp;
                    <p>{subject.text}</p>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
