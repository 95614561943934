import React, { useEffect, useState } from 'react';
import { Clock } from './Clock';

import './styles.scss';

const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export const Timer = ({ startTs, duration, clubState, changeClubState, setIsButtonShowed }) => {
  const startDate = +new Date(startTs);
  const durationInMS = duration * 60 * 1000;
  const endTime = durationInMS + startDate;
  const [currentClock, setCurrentClock] = useState(0);
  const [currentInterval, setCurrentInterval] = useState(null);
  const [currentOffset, setCurrentOffset] = useState(null);

  const [daysTo, setDaysTo] = useState(null);

  const timeFormatArray = ['HH', 'mm', 'ss'];

  const countDaysTo = () => {
    const timeTo = startDate - +new Date();
    let daysLeft = 0;
    if (timeTo > ONE_DAY_IN_MILLISECONDS) {
      daysLeft = timeTo / ONE_DAY_IN_MILLISECONDS;
    }
    setDaysTo(daysLeft);
  };

  const play = () => {
    if (!currentInterval) {
      setCurrentOffset(startDate - new Date());
      update();
      setCurrentInterval(setInterval(update, 1000));
    }
  };

  const update = () => {
    let clock = currentClock ;
    clock += calculateOffset();
    setCurrentClock(clock);
    if (changeClubState) {
      if (Date.now() > endTime) {
        if (clubState !== 'finished') {
          changeClubState('finished');
          setIsButtonShowed && setIsButtonShowed(false);
        }
      } else if (Date.now() > startDate) {
        if (clubState !== 'in_process') {
          changeClubState('in_process');
        }
      } else if (Date.now() < startDate) {
        if (clubState !== 'pending') {
          changeClubState('pending');
        }

        if (Date.now() >= startDate - 600000) {
          setIsButtonShowed && setIsButtonShowed(true);
        }
      }
    }
  };

  const calculateOffset = () => {
    const now = startDate - Date.now();
    const newOffset = now - currentOffset;
    setCurrentOffset(now);
    return newOffset;
  };

  useEffect(() => {
    if (startDate) {
      play();
      if (daysTo === null) {
        countDaysTo();
      }
    }
  });

  return (
    startDate > Date.now() && daysTo <= 0 ? (
      <div className={'timer-container'}>
        <p className={'timer-header-text'}>Урок начнется через:</p>
        <Clock formatArray={timeFormatArray} dateToDisplay={currentClock}/>
      </div>
    ) : null
  );
};
