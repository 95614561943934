import React from 'react';

import './styles.scss';

export const  PublicOfferAgreement = () => {
  return (
    <div className="info-container">
      <p>ПУБЛИЧНАЯ ОФЕРТА</p>
      <p>Сайт «lessonslab.com», расположенный на доменном имени<br />
        www.lessonslab.com, ФЛП Макиян Е.А., и юридическим адресом г. Киев, Русановская набережная 14/1, кв. 6,<br />
        в лице Макияна Е.А., действующего на основании свидетельства о государственной регистрации физической особы предпринимателя,<br />
        именуемое в дальнейшем «Продавец», публикует Публичную оферту о продаже Услуги дистанционным способом.</p>
      <p>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</p>
      <p>1.1. Публичная оферта (далее – «Оферта») &#8212; публичное предложение Продавца, адресованное неопределенному кругу лиц, заключить с Продавцом договор купли-продажи услуги дистанционным способом (далее &#8212; «Договор») на условиях, содержащихся в настоящей Оферте, включая все Приложения.<br />
        1.2. Услуга (далее &#8212; «Услуга») – любые уроки, которые вы можете увидеть на сайте www.lessonslab.com онлайн.<br />
        1.3. Заказ Услуги на сайте – позиции указанные Покупателем из ассортимента Услуг, предложенного к продаже, при оформлении заявки на приобретение Услуги на сайте или через Оператора.<br />
        2. ОБЩИЕ ПОЛОЖЕНИЯ<br />
        2.1. Заказ Покупателем Услуги, размещенной на сайте означает, что Покупатель согласен со всеми условиями настоящей Оферты.<br />
        2.2. Данная Услуга (видео-урок) предназначен только для личного использования. <br />
        2.3. Администрация сайта «lessonslab.com» имеет право вносить изменения в Оферту без уведомления Покупателя.<br />
        2.4. Срок действия Оферты не ограничен, если иное не указано на сайте «lessonslab.com».<br />
        3. ЦЕНА ТОВАРА<br />
        3.1. Цена на каждую позицию Услуги указана на сайте «lessonslab.com».<br />
        3.2. Продавец имеет право в одностороннем порядке изменить цену на любую позицию Услуги.<br />
        3.3. В случае изменения цены на заказанную Услугу Продавец обязуется в течение 3(трех) дней проинформировать Покупателя об изменении цены Услуги.<br />
        3.4. Покупатель вправе подтвердить либо аннулировать Заказ на приобретение Услуги, если цена изменена Продавцом после оформления Заказа.<br />
        3.5. Изменение Продавцом цены на оплаченную Покупателем Услугу не допускается.<br />
        3.6. Обязательства Покупателя по оплате Услуги считаются исполненными с момента поступления Продавцом денежных средств.<br />
        3.7. Расчеты между Продавцом и Покупателем за Услугу производятся способами, указанными на сайте «lessonslab.com» <br />
        4. ОФОРМЛЕНИЕ ЗАКАЗА<br />
        4.1. Заказ Услуги осуществляется Покупателем через сервис сайта «lessonslab.com»<br />
        4.2. Наименование, количество, ассортимент, артикул, цена выбранной Покупателем Услуги указываются на сайте «lessonslab.com».<br />
        4.3. Если Продавцу необходима дополнительная информация, он вправе запросить ее у Покупателя. В случае не предоставления необходимой информации Покупателем, Продавец не несет ответственности за выбранную Покупателем Услугу.<br />
        4.4. Принятие Покупателем условий настоящей Оферты осуществляется посредством внесения платы за услугу.<br />
        4.5. Продавец не несет ответственности за содержание и достоверность информации, предоставленной Покупателем при оформлении Заказа.<br />
        4.6. Покупатель несет ответственность за достоверность предоставленной информации при оформлении Заказа.<br />
        4.7. Договор купли-продажи дистанционным способом между Продавцом и Покупателем считается заключенным с момента получения Продавцом сообщения о намерении Покупателя приобрести Услугу.<br />
        5. ДОСТАВКА И ПЕРЕДАЧА ТОВАРА ПОКУПАТЕЛЮ<br />
        5.1. Покупателю придёт ссылка на указанный e-mail Если нет – смотрите папку «Спам».<br />
        5.2. Возврат услуги не предусматривается.<br />
        6.ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ и COOKIE<br />
        6.1. Ознакомиться с политикой конфиденциальности и cookie можно на странице: <a href="https://lessonslab.com/privacy-policy" target="_blank" rel="noopener noreferrer">https://lessonslab.com/privacy-policy</a><br />
        7. РЕКВИЗИТЫ ПРОДАВЦА<br />
        Продавец:<br />
        Макиян Е.А. , ФЛП<br />
        <a href="tel: +38063292165"> +38063292165</a><br />
        e-mail:<a href="mailto:advinadvin@gmail.com">advinadvin@gmail.com</a><br />
        Адрес:<br />
        г. Киев, Русановская набережная 14/1, кв. 6</p>
      <p>Внимательно ознакомьтесь с текстом публичной оферты, и если Вы не согласны с каким-либо пунктом оферты, Вы вправе отказаться от покупки Услуг, предоставляемых Продавцом, и не совершать действий, указанный в п. 2.1. настоящей Оферты.</p>
      <p>Согласен с договором</p>
    </div>
  );
};
