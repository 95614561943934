import React, { useEffect, useState } from 'react';
import { TextField, Button, Popover } from '@material-ui/core';
import axios from 'axios';
import { API_URL } from '../constants';
import { LoaderComponent } from '../loader';
import { LessonsList } from '../lessonsList';
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SortIcon from '@material-ui/icons/Sort';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import ScheduleIcon from '@material-ui/icons/Schedule';
import StarRateIcon from '@material-ui/icons/StarRate';
import { FilterModal } from '../filterModal';
import { useHistory } from 'react-router';

import './styles.scss';

const sortByData = [
  {
    text: 'Цена по возрастанию',
    value: 'priceUp',
    icon: <ArrowUpwardIcon />
  },
  {
    text: 'Цена по убыванию',
    value: 'priceDown',
    icon: <ArrowDownwardIcon />
  },
  {
    text: 'Новые уроки',
    value: 'newLessons',
    icon: <FiberNewIcon />
  },
  {
    text: 'Ближайшие уроки',
    value: 'startSoon',
    icon: <ScheduleIcon />
  },
  {
    text: 'Рейтинг учителя',
    value: 'teacherRate',
    icon: <StarRateIcon />
  }
];


export const MyLessons = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [lessons, setLessons] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));
  const [isModalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortValue, setSortValue] = useState({
    text: 'Ближайшие уроки',
    icon: <ScheduleIcon />
  });
  const subject = decodeURI(history.location.search).replace('?subject=', '');
  const filteredLessons = lessons.filter(lesson => lesson.theme === subject);

  useEffect(() => {
    if (history.location.search) {
      if (subject && filteredLessons.length !== lessons.length && !searchResults) {
        setSearchResults(filteredLessons);
      }
    }
  }, [history.location.search, subject, filteredLessons, lessons.length, searchResults]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onSearch = event => {
    const searchTerm = event.target.value;
    const results = lessons.filter(lesson => {
      return lesson.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
    });

    setSearchResults(results);
  };

  const onFilterSearch = (filterData) => {
    let res;
    if (!filterData) {
      setSearchResults(lessons);
      return;
    }

    if (filterData && filterData.topicsArray.length && !filterData.dayRange.length) {
      res = lessons.filter((item) => {
      return (item.price >= filterData.priceRange[0] && item.price <= filterData.priceRange[1]) &&
        (item.ageGroupFrom >= filterData.ageRange[0] && item.ageGroupTo <= filterData.ageRange[1]) &&
        filterData.topicsArray.includes(item.theme) &&
        (new Date(item.startTs).getHours() >= filterData.timeRange[0] && new Date(item.startTs).getHours() <= filterData.timeRange[1])
      });
    } else if (filterData && !filterData.topicsArray.length && filterData.dayRange.length) {
      res = lessons.filter((item) => {
        return (item.price >= filterData.priceRange[0] && item.price <= filterData.priceRange[1]) &&
          (item.ageGroupFrom >= filterData.ageRange[0] && item.ageGroupTo <= filterData.ageRange[1]) &&
          filterData.dayRange.includes(new Date(item.startTs).getDay()) &&
          (new Date(item.startTs).getHours() >= filterData.timeRange[0] && new Date(item.startTs).getHours() <= filterData.timeRange[1])
      });
    } else if (filterData && filterData.topicsArray.length && filterData.dayRange.length) {
      res = lessons.filter((item) => {
        return (item.price >= filterData.priceRange[0] && item.price <= filterData.priceRange[1]) &&
          (item.ageGroupFrom >= filterData.ageRange[0] && item.ageGroupTo <= filterData.ageRange[1]) &&
          filterData.dayRange.includes(new Date(item.startTs).getDay()) &&
          filterData.topicsArray.includes(item.theme) &&
          (new Date(item.startTs).getHours() >= filterData.timeRange[0] && new Date(item.startTs).getHours() <= filterData.timeRange[1])
      });
    } else {
      res = lessons.filter((item) => {
        return (item.price >= filterData.priceRange[0] && item.price <= filterData.priceRange[1]) &&
        (item.ageGroupFrom >= filterData.ageRange[0] && item.ageGroupTo <= filterData.ageRange[1]) &&
        (new Date(item.startTs).getHours() >= filterData.timeRange[0] && new Date(item.startTs).getHours() <= filterData.timeRange[1])
      });
    }
    setSearchResults(res);
  };

  const getTeacherLessons = async () => {
    try {
      setIsLoading(true);
      let lessonsData = await axios.get(`${API_URL}/user-lessons/${user._id}`);
      lessonsData = await lessonsData.data.slice().sort((a, b) => {
        const dateOne = new Date(a.startTs);
        const dateTwo = new Date(b.startTs);
        return dateOne - dateTwo;
      });
      lessonsData.filter(item => {
        return + new Date(item.startTs) < +new Date();
        }
      );
      setLessons(lessonsData);
    } catch (err) {
      console.log('Error', err);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllLessons = async () => {
    try {
      setIsLoading(true);
      let lessonsData = await axios.get(`${API_URL}/lessons`);
      const newLessons = lessonsData.data.filter((el) => new Date(el.startTs) > new Date());
      const sortedLessons = await newLessons.slice().sort((a, b) => {
        const dateOne = new Date(a.startTs);
        const dateTwo = new Date(b.startTs);
        return dateOne - dateTwo;
      });
      setLessons(sortedLessons);
    } catch (err) {
      console.log('Error', err);
    } finally {
      setIsLoading(false);
    }
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  const openPopover = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const applyTeacherSort = (filterData) => {
    let res;
    if (!filterData) {
      setSearchResults(lessons);
      return;
    }

    if (user && user.type === 'teacher') {
      if (filterData.includes('archivedLessons') && filterData.length === 1 ) {
        res = lessons.filter((item) => {
          return +new Date(item.startTs) <= +new Date();
        })
      } else if (filterData.includes('nearestLessons') && filterData.length === 1 ) {
        res = lessons.filter((item) => {
          return +new Date(item.startTs) > +new Date();
        })
      } else if (filterData.includes('archivedLessons') && filterData.includes('nearestLessons')){
        res = lessons;
      }
      setSearchResults(res);
    }
  };

  const applySort = (value, icon, text) => {
    let sortedData;
    if (value === 'priceUp') {
      sortedData = lessons.slice().sort((a, b) => {
        return a.price - b.price;
      });
    } else if (value === 'priceDown') {
      sortedData = lessons.slice().sort((a, b) => {
        return b.price - a.price;
      });
    } else if (value === 'newLessons') {
      sortedData = lessons.slice().sort((a, b) => {
        return +new Date(b.createTs) - +new Date(a.createTs);
      });
    } else if (value === 'archive') {
      sortedData = lessons.filter(item => {
        return + new Date(item.startTs) < +new Date();
        });
    } else if (value === 'teacherRate') {
      sortedData = lessons.slice().sort((a, b) => {
        let teacherAveragePrevious = 0;
        let teacherAverageNext = 0;
        for (const rate of a.host.rating) {
          teacherAveragePrevious = (teacherAveragePrevious + +rate.value) / a.host.rating.length;
        }
        for (const rate of b.host.rating) {
          teacherAverageNext = (teacherAverageNext + +rate.value) / b.host.rating.length;
        }
        return teacherAverageNext - teacherAveragePrevious;
      });
    } else {
      sortedData = lessons.slice().sort((a, b) => {
        return new Date(a.startTs) - new Date(b.startTs);
      });
    }
    setSortValue({
      text,
      icon
    });
    setLessons(sortedData)
    setAnchorEl(null);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (user && user.type === 'teacher' && history.location.pathname !== '/lessons') {
      getTeacherLessons();
    } else {
      getAllLessons();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <LoaderComponent />
  }

  return (
    <div className="search-lessons-wrapper">
      <div className="search-filter-wrapper">
        <TextField
          label="Найти урок"
          type='search'
          className='search-input'
          onChange={(e) => onSearch(e)}
        />


        <div className="sort-popover-wrapper">
          <div className="sort-button-wrapper">
            <Button variant="outlined" onClick={() => setModalOpen(true)}>
              <FilterListIcon />
            </Button>

            <div className="sort-value">
              <Button variant="outlined" onClick={openPopover}>
                <SortIcon />
              </Button>
              {sortValue.icon}
              <p>{sortValue.text}</p>
            </div>
          </div>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={closePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div className="sort-popover-content">
              <p>Отсортировать по:</p>
              {sortByData.map((item, index) => {
                return (
                  <div key={index} className="sort-item" onClick={() => applySort(item.value, item.icon, item.text)}>
                    {item.icon}
                    <p>{item.text}</p>
                  </div>
                )
              })
              }
            </div>
          </Popover>
        </div>

        {user && user.type === 'teacher' && history.location.pathname !== '/lessons' ? (
          <FilterModal
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            applyFilterChanges={applyTeacherSort}
            personalSort
            topicsArray={subject ? [subject] : []}
          />
        ) : (
          <FilterModal
              isModalOpen={isModalOpen}
              setModalOpen={setModalOpen}
              applyFilterChanges={onFilterSearch}
              topicsArray={subject ? [subject] : []}
          />
        )}

      </div>
        <LessonsList lessonsDate={searchResults ? searchResults : lessons} loading={setIsLoading}/>
        {(!user || (user.type !== 'teacher')) &&
          (
            <div className="right-search">
              <p>Не нашли того, что искали?</p>
              <p>Закажите тему урока</p>
              <p><a href="mailto:support@lessonslab.com">support@lessonslab.com</a></p>
            </div>
          )
        }
    </div>
  );
};
