import React, { Fragment } from 'react';
import moment from 'moment';

import './styles.scss';

export const Clock = ({ formatArray, dateToDisplay }) => {
  const divider = formatArray[0] === 'HH' ? ':' : '/';

  return (
    <div className={'current-time-container'}>
      {
        formatArray.map((el, i) => (
          <Fragment key={i}>
            <div className={'number-container'}>
              {
                el.split('').map((e, j) => (
                  <div className={'digit-container'} key={`${i}-${j}`}>
                    {
                      formatArray[0] === 'HH'
                        ? <p className={'digit-text'}>{moment.utc(dateToDisplay).format(el)[j]}</p>
                        : <p className={'digit-text'}>{moment(dateToDisplay).format(el)[j]}</p>
                    }
                    <div className={'digit-hover'}/>
                  </div>
                ))
              }
            </div>
            {
              i < 2 && <p className={'numbers-divider'}>{divider}</p>
            }
          </Fragment>
        ))
      }
    </div>
  );
};
