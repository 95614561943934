import React, { useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Rating from '@material-ui/lab/Rating';
import { LoaderComponent } from '../../components/loader';

import { trimDate } from '../../utils';
import axios from 'axios';
import { API_URL } from '../../components/constants';
import firebase from 'firebase';

import './styles.scss';

const googleProvider = new firebase.auth.GoogleAuthProvider();

export const ReviewLessonPage = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [isRatingDisabled, setIsRatingDisabled] = useState(null);
  const [lesson, setLessonData] = useState({});
  const lessonId = history.location.pathname.split('/')[2];
  const user = JSON.parse(localStorage.getItem('user'));
  const [userData, setUserData] = useState(user);

  const goHome = () => {
    history.push('/')
  }

  const getLessonInfo = async () => {
    try {
      setIsLoading(true);
      const result = await axios.get(`${API_URL}/lessons/${lessonId}`);
      setLessonData(result.data);

      const isRatingDisabled = result.data.rating.filter((item) => {
        return item.userId === user._id;
      });
      if (result.data.rating.length > 0) {
        setIsRatingDisabled(isRatingDisabled);
        setRating(isRatingDisabled[0].value);
      }
    } catch (err) {
      console.log('Get lesson info error:', err);
    } finally {
      setIsLoading(false);
    }
  }

  const setLessonRating = async (event) => {
    const ratingValue = event.target.value;
    const ratingData = {
      rating: [...lesson.rating]
    }

    const ratingObject = {
      userId: user._id,
      value: ratingValue,
      timestamp: +new Date()
    }

    ratingData.rating.push(ratingObject);

    try {
      setIsLoading(true);
      setRating(ratingValue);
      const result = await axios.put(`${API_URL}/lessons/${lessonId}`, ratingData);

      const isRatingDisabled = result.data.rating.filter((item) => {
        return item.userId === user._id;
      });

      console.log('isRatingDisabled', isRatingDisabled);
      setRating(isRatingDisabled[0].value);
      setIsRatingDisabled(isRatingDisabled);
      history.push(`/lesson/${lessonId}`)
    } catch (err) {
      console.log('Set user rating error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const login = async () => {
    try {
      setIsLoading(true);
      const { user } = await firebase.auth().signInWithPopup(googleProvider);

      const result = await axios.post(`${API_URL}/login`, {
        email: user.email,
        displayName: user.displayName,
        fireId: user.uid,
        avatar: {
          url: user.photoURL,
        },
      });
      console.log('log222');
      localStorage.setItem('user', JSON.stringify(result.data));
      setIsRatingDisabled(false);
    } catch (error) {
      alert(`Login error.` + (error.message ? ` ${error.message}` : ''));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!userData) {
      setIsRatingDisabled(true);
      setRating(0);
    } else {
      setUserData(user);
    }
    getLessonInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <LoaderComponent />
  }

  return (
    <div className='review-lesson-container'>
      <div className="header-container">
        <img src={require('../../assets/img/logo.png')} className='logo' alt="logo" onClick={goHome}/>
        {!user && (<Button variant="outlined" onClick={login}>Войти</Button>)}
      </div>
      <div className='rating-wrapper'>
        {isRatingDisabled && !user && (
          <h3>Необходимо войти в учетную запись, чтобы поставить оценку</h3>
        )}

        {user && (
          <h3>{`${!!isRatingDisabled ? 'Вы уже оценили' : 'Оцените'} пройденный урок - ${lesson.name} - ${trimDate(lesson.startTs)}`}</h3>
        )}
        <Box component="fieldset" mb={3} borderColor="transparent">
          <Rating
            disabled={!!isRatingDisabled}
            name="simple-controlled"
            value={rating}
            onChange={setLessonRating}
            size='large'
          />
        </Box>
      </div>
    </div>
  );
};
