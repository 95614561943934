import React, { useState } from 'react';
import { Button, Dialog, FormControlLabel, Checkbox, Slider } from '@material-ui/core';
import { TOPICS } from '../constants';

import './styles.scss';

const initialValues = {
  topicsArray: [],
  priceRange: [0, 50],
  ageRange: [0, 18],
  dayRange: [],
  timeRange: [0, 24]
};

const dayRangeValues = [
  {
    day: 'Пн',
    value: 1
  },
  {
    day: 'Вт',
    value: 2
  },
  {
    day: 'Ср',
    value: 3
  },
  {
    day: 'Чт',
    value: 4
  },
  {
    day: 'Пт',
    value: 5
  },
  {
    day: 'Сб',
    value: 6
  },
  {
    day: 'Вс',
    value: 7
  },
]

export const FilterModal = (
  {
    isModalOpen,
    setModalOpen,
    applyFilterChanges,
    personalSort,
    topicsArray=[],
  }) => {
  const [fieldsData, setFieldsData] = useState({...initialValues, topicsArray});
  const [weekDays, setWeekDays] = useState(initialValues.dayRange);
  const [teacherFilterFields, setTeacherFilterFields] = useState([]);

  const onModalFieldChange = ({ field, value }) => {
    setFieldsData({ ...fieldsData, ...{ [field]: value } });
  };

  const checkBoxHandleChange = (e) => {
    const topicValue = e.target.value;
    if (!fieldsData.topicsArray.includes(topicValue)) {
      const arrCopy = [...fieldsData.topicsArray];
      arrCopy.push(topicValue);
      onModalFieldChange({
        field: 'topicsArray',
        value: arrCopy
      });
    } else {
      const filteredCategories = [...fieldsData.topicsArray].filter((item) => item !== topicValue);
      onModalFieldChange({
        field: 'topicsArray',
        value: filteredCategories
      });
    }
  };

  const checkBoxTeacherFilter = (e) => {
    const teacherFilterValue = e.target.value;
    if (!teacherFilterFields.includes(teacherFilterValue)) {
      const arrCopy = [...teacherFilterFields];
      arrCopy.push(teacherFilterValue);
      setTeacherFilterFields(arrCopy);
    } else {
      const filteredValues = [...teacherFilterFields].filter((item) => item !== teacherFilterValue);
      setTeacherFilterFields(filteredValues);
    }
  }

  const onPriceRangeChange = (event, newValue) => {
    onModalFieldChange({
      field: 'priceRange',
      value: newValue
    });
  }

  const onAgeRangeChange = (event, newValue) => {
    onModalFieldChange({
      field: 'ageRange',
      value: newValue
    });
  };

  const onTimeRangeChange = (event, newValue) => {
    onModalFieldChange({
      field: 'timeRange',
      value: newValue
    });
  };

  const onModalCLose = () => {
    setModalOpen(false);
    if (personalSort) {
      applyFilterChanges(teacherFilterFields);
    } else {
      applyFilterChanges(fieldsData);
    }
  };

  const filterCancel = () => {
    setFieldsData({...initialValues, topicsArray});
    applyFilterChanges(null);
    setModalOpen(false);
  }

  const applyFilter = () => {
    applyFilterChanges(fieldsData);
    setModalOpen(false);
  };

  const applyFilterForTeacher = () => {
    applyFilterChanges(teacherFilterFields);
    setModalOpen(false);
  };

  const dayRangeChange = (day) => {
    let days = [...weekDays];
    if (days.includes(day)) {
      days = days.filter((el) => (
        el !== day
      ));
    } else {
      days.push(day);
    }
    days.sort((a, b) => (a - b));
    setWeekDays(days);
    onModalFieldChange({
      field: 'dayRange',
      value: days
    });
  };

  return (
      <div className="filter-modal">
        <Dialog open={isModalOpen} onClose={onModalCLose}>
          <div className="filter-modal-container">
            {personalSort ? (
                <div className="filter-modal-wrapper">
                  <FormControlLabel
                      control={
                        <Checkbox
                            value="archivedLessons"
                            checked={teacherFilterFields.includes('archivedLessons')}
                            onChange={(e) => checkBoxTeacherFilter(e)}
                            name="checkedB"
                            color="primary"
                        />
                      }
                      label="Архивные уроки"
                  />

                  <FormControlLabel
                      control={
                        <Checkbox
                            value="nearestLessons"
                            checked={teacherFilterFields.includes('nearestLessons')}
                            onChange={(e) => checkBoxTeacherFilter(e)}
                            name="checkedB"
                            color="primary"
                        />
                      }
                      label="Предстоящие уроки"
                  />
                  <Button variant="outlined" onClick={applyFilterForTeacher}>Применить</Button>
                </div>
            ) : (
                <div className="filter-modal-wrapper">
                  <h3>Темы</h3>
                  {
                    TOPICS.map((item, index) => {
                      return (
                          <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                    value={item.text}
                                    checked={fieldsData.topicsArray.includes(item.text)}
                                    onChange={(e) => checkBoxHandleChange(e)}
                                    name="checkedB"
                                    color="primary"
                                />
                              }
                              label={item.text}
                          />
                      )
                    })
                  }
                  <h3>Цена</h3>
                  <div className="range-modal-container">
                    <p>0</p>
                    <Slider
                        value={fieldsData.priceRange}
                        onChange={onPriceRangeChange}
                        min={0}
                        step={1}
                        max={50}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                    />
                    <p>50 $</p>
                  </div>

                  <h3>Возраст</h3>
                  <div className="range-modal-container">
                    <p>0</p>
                    <Slider
                        value={fieldsData.ageRange}
                        onChange={onAgeRangeChange}
                        min={0}
                        step={1}
                        max={18}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                    />
                    <p>18</p>
                  </div>

                  <h3>День недели</h3>
                  <div>
                    {dayRangeValues.map((item, index) => (
                        <Button
                            key={'button' + index}
                            variant={weekDays.includes(item.value) ? "outlined" : "text"}
                            value={item.value}
                            onClick={() => dayRangeChange(item.value)}
                        >
                          {item.day}
                        </Button>
                    ))
                    }
                  </div>

                  <h3>Время начала урока</h3>
                  <div className="range-modal-container">
                    <p>0 ч</p>
                    <Slider
                        value={fieldsData.timeRange}
                        onChange={onTimeRangeChange}
                        min={0}
                        step={1}
                        max={24}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                    />
                    <p>24 ч</p>
                  </div>

                  <div className="button-container">
                    <Button variant="outlined" onClick={filterCancel}>Сбросить</Button>
                    <Button variant="outlined" onClick={applyFilter}>Применить</Button>
                  </div>
                </div>
            )}
          </div>
        </Dialog>
      </div>
  );
};
