import React, { useEffect, useState } from 'react';
import { Tabs, AppBar, Tab, Box, Typography } from '@material-ui/core';
import { Profile } from '../profile';
import { SubscribedLessons } from '../subscribedLessons';
import { FavoriteLessons } from '../favoriteLessons';
import { useHistory } from 'react-router-dom';
import { Calendar } from '../calendar';

import './styles.scss';

export const Student = () => {
  const history = useHistory();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    if (
      history.location.state &&
      history.location.state.hasOwnProperty('cameFromCalendar') &&
      history.location.state.cameFromCalendar
    ) {
      setValue(1);
      history.replace('/profile', {});
    }

    if (history.location.state?.tabValue) {
      setValue(history.location.state.tabValue);
      history.push('/profile', {});
    }
  }, [history]);

  return (
    <div className='teacher-container'>
      <h2>Страница ученика</h2>

      <div>
        <AppBar position="static">
          <Tabs className='tabs-container' value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Мои Уроки" {...a11yProps(0)} />
            <Tab label="Календарь" {...a11yProps(1)} />
            <Tab label="Избранное" {...a11yProps(2)} />
            <Tab label="Профайл" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <SubscribedLessons />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Calendar />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <FavoriteLessons />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Profile />
        </TabPanel>
      </div>
    </div>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};
