import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { Welcome } from './pages/welcome';
import { PersonType } from './pages/personType';
import { Profile } from './pages/profilePage';
import { Lessons } from './pages/lessons';
import { LessonPage } from './pages/lessonPage';
import { TeacherProfilePage } from './pages/teacherProfilePage';
import { PaymentStatusPage } from './pages/paymentStatus';
import { Footer } from './components/footer';
import { PrivacyPolicy } from './pages/privacyPolicy';
import { PublicOfferAgreement } from './pages/publicOfferAgreement';
import { ReviewLessonPage } from './pages/reviewLessonPage';

import './App.scss';

export const App = () => {
  return (
    <div className="page-container">
      <BrowserRouter>
        <div className="content">
          <Switch>
            <Route path='/home'>
              <Welcome />
            </Route>
            <Route path='/person-type/:id'>
              <PersonType />
            </Route>
            <Route path='/profile'>
              <Profile />
            </Route>
            <Route path='/lessons'>
              <Lessons />
            </Route>
            <Route path='/payment-status'>
              <PaymentStatusPage />
            </Route>
            <Route path='/privacy-policy'>
              <PrivacyPolicy />
            </Route>
            <Route path='/public-agreement'>
              <PublicOfferAgreement />
            </Route>

            <Route path={'/lesson/:id/rating'} render={(props) => <ReviewLessonPage {...props} />}/>
            <Route path={'/lesson/:id'} render={(props) => <LessonPage {...props} />}/>
            <Route path={'/teacher/:id'} render={(props) => <TeacherProfilePage {...props} />}/>
            <Redirect
              to={{
                pathname: '/home',
                state: { from: '**' }
              }}
            />
          </Switch>
        </div>

        <div className="footer">
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
