import React from 'react';

import './styles.scss';

export const UsefulTips = () => {
  return (
    <div className='tips-container'>
      <p>
<h4>О нас</h4>
<span>Lessonslab</span> - это не традиционная онлайн школа, а место встречи учителей и учеников со всего мира. Многие из наших учителей воспользовались этой возможностью, чтобы проявить свой творческий потенциал! У нас есть уроки, которые охватывают несколько предметных областей, учителя, которые преподают традиционные темы под необычным ракурсом, используют интересные задания и материалы, чтобы привлечь внимание учащихся, или бросают вызов традиционным методам обучения. Lessonslab не предоставляет учителям учебную программу, вы вольны преподавать так, как вы считаете нужным.<br />
Преподаватели Lessonslab - независимые подрядчики и владельцы собственного учебного бизнеса.<br />
Мы предоставляем платформу, рекламу и техническую поддержку, чтобы вы могли сосредоточиться только на том, что вам действительно нравится, преподавании и ведении своего бизнеса.<br />
Это означает, что вы сами выбираете цены, расписание и темы занятий. Обратите внимание: как учитель Lessonslab вы не являетесь сотрудником Lessonslab.
</p>

<p>
<h4>Темы уроков</h4>
Учителя Lessonslab могут создавать и проводить уроки по темам, которые им наиболее интересны и в которых они разбираются. <br />
Родители приходят в Lessonslab в поисках как основных учебных предметов, так и хобби, увлечений, а также их сочетания. <br />
Учащиеся Lessonslab из разных стран, и семьи следуют разным философиям обучения и уроки Lessonslab не обязательно должны соответствовать каким-либо стандартам учебной программы.<br />
Урок может быть как самостоятельным, так и составляющей частью курса (укажите в описании урока, что урок является частью курса и создайте несколько уроков. Например, “Учим дроби 1”, “Учим дроби 2” ...)<br />
Урок может быть как индивидуальным, так и групповым (вы можете выбрать нужный вам вариант при создании урока).<br />
Свой урок учителя могут предлагать столько раз, сколько захотят. Просто нажмите кнопку  “повторить урок” на странице вашего урока и выберите новое время проведения занятия.<br />
Для примера, вы можете ознакомиться с уроками других учителей <a href="https://lessonslab.com/lessons">здесь</a>
</p>

<p>
<h4>Время проведения занятий</h4>
Вы вольны преподавать тогда, когда вам удобно, но обратите внимание, что наши ученики часто находятся с вами в разных временных поясах. 
</p>
<br />

      <div className='time-tips'>
        <img className='time-tips-img' src={require('../../assets/img/tips_time.jpg')} alt='tips-time'/>
        <p className='time-tips-img-desc'>*Киевское время</p>
      </div>

      <br />
<p>      
Кроме того, мы советуем создавать уроки заранее (минимум за неделю до предполагаемого времени проведения урока). Так у вас будет достаточно времени для того, чтобы набрать нужное количество учеников в группу.
</p>

<p>
<h4>Оплата</h4>
Учитель сам устанавливает цену за свой урок. Обычно мы рекомендуем начинать с средней цены $7-10 с ученика за урок. <br />
Сотрудничество с Lessonslab подразумевает то, что каждый раз при зачислении учащегося с него взимается плата за обслуживание. Lessonslab берет 30% оплаты с родителей, а учителя получают 70% (учтите это при формировании цены урока). <br />
Lessonslab зарабатывает только тогда, когда зарабатываете вы, и наши цели совпадают. Все сборы за занятия оплачиваются через платформу Lessonslab.<br />
Вы получаете свою оплату в течение 3 рабочих дней после проведения урока. Оплаты производятся на карту, которую вы укажите при регистрации. Необходимо также учитывать комиссию банка, при выводе средств: 1.10% + 10 грн при использовании карты Приват банка или 1.10% + 20 грн - при использовании любой другой карты.
</p>

<p>
<h4>Ученики Lessonslab</h4>
Наши ученики - это дети со всего мира от 3 до 18 лет. Часто русский или украинский язык, это их второй язык, это стоит учитывать при подготовке урока. <br />
Занятия в Lessonslab проходят на платформе Zoom в реальном времени, которая интегрирована прямо в веб-сайт Lessonslab. Zoom прост в использовании и имеет множество замечательных функций для учителей!<br />
Скачать и  установить приложение Zoom можно по ссылке <a href="https://zoom.us/download">https://zoom.us/download</a><br />
Все классы записываются в целях безопасности и обеспечения качества оказанных услуг. 
</p>

<p>
<h4>Кто может преподавать:</h4>
Учитель должен являться гражданином Украины старше 14 лет, быть специалистом в своей области, хотеть и уметь работать с детьми.
</p>

<p>
<h4>Технических требования:</h4>
<ul>
<li>Ноутбук или настольный компьютер</li>
<li>Веб-камера и микрофон (можно встроенные)</li>
<li>Должны быть соблюдены технические требования Zoom, посмотреть которые вы можете посмотреть по ссылке:<br />
<a href="https://support.zoom.us/hc/ru/articles/201362023-%D0%A1%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%BD%D1%8B%D0%B5-%D1%82%D1%80%D0%B5%D0%B1%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%B4%D0%BB%D1%8F-%D0%9E%D0%A1-Windows-macOS-%D0%B8-Linux">
              Системные требования для ОС Windows, macOS и Linux
            </a></li>
</ul>
</p>

<p>
<h4>Вы готовы начать преподавание с нами?</h4> 
Добро пожаловать в наш дружный коллектив! Для начала вам нужно будет заполнить профиль с подробным описанием вашего опыта преподавания и знаний в предметной области. Не забудьте указать как можно больше подробностей - чем больше мы знаем о вас, тем легче нам будет определить подходим ли мы друг другу. Обязательно приложите все усилия и проверьте свою заявку на орфографические и грамматические ошибки.
</p>

<p>
Вам также нужно будет записать короткую видео-визитку (3-5 минут), чтобы представиться, познакомить нас с темой уроков, которые вы будете преподавать, и продемонстрировать свое учебное пространство. Вы можете загрузить видео до 100 Мб.
</p>

<p>
Как только ваша заявка будет одобрена, вы сможете начать создание уроков.
</p>

<p>
<h4 className="text-center">Удачи и добро пожаловать!</h4>
</p>
    </div>
  );
};

