import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { API_URL } from '../constants';
import { LoaderComponent } from '../loader';
import { trimDate } from '../../utils';
import { Button, TextField } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CancelIcon from '@material-ui/icons/Cancel';

import './styles.scss'

const chooseFile = () => {
  document.getElementById('fake-file-input').value = null;
  document.getElementById('fake-file-input').click();
};

export const ReviewsListWithAttach = ({ getLessonInfo, lessonId, lessonData }) => {
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [file, setFile] = useState(null);
  const [isFileUpload, setFileUpload] = useState(false);
  const [fileFullObject, setFileFullObject] = useState(null);
  const [commentText, setComment] = useState(null);
  const [fileName, setFileName] = useState('');
  const [lessonComments, setLessonComments] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'));

  const addLessonComment = async () => {
    try {
      const commentData = {
        comments: [...lessonData.comments]
      };

      const commentObject = {
        author: user && user._id,
        timestamp: +new Date(),
        text: commentText,
        attachments: []
      };

      commentObject.attachments.push(fileFullObject);
      commentData.comments.push(commentObject);

      setIsLoading(true);
      await axios.put(`${API_URL}/lessons/${lessonId}`, commentData);
      await getLessonInfo();
    } catch (err) {
      console.log('Add comment error:')
    } finally {
      setIsLoading(false);
    }
  };

  const uploadFile = async (e) => {
    try {
      setFileUpload(true);
      if (e.target.files) {
        setFileName(e.target.files[0].name);
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.addEventListener('load', () => {
          setFile(reader.result);
        });

        reader.readAsDataURL(e.target.files[0]);
        const data = new FormData()
        data.append('file', file);

        const fileResult = await axios.post(`${API_URL}/images`, data);
        setFileFullObject(fileResult.data);

      }
    } catch (err) {
      console.log('Upload file error:', err)
    } finally {
      setFileUpload(false);
    }
  };

  const cancelAttachFile = async () => {
    const fileFireId = fileFullObject?.url?.split('/')[9]?.split('_')[0];
    const fileFireName = `${fileFireId}_${fileFullObject.name}`;

    try {
      setIsLoading(true);
      await axios.delete(`${API_URL}/images/${fileFireName}`);
      setFileFullObject(null);
      setFileName(null);
    } catch (err) {
      console.log('Cancel attach file error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function getLessonComments() {
      try {
        setIsLoading(true);
        const result = await axios.get(`${API_URL}/lesson-comments/${lessonId}`);
        const sortedData = result.data.slice().sort(function(x, y){
          return new Date(y.timestamp) - new Date(x.timestamp);
        })
        setLessonComments(sortedData);
      } catch (err) {
        console.log('Get lesson comments error:', err);
      } finally {
        setIsLoading(false);
      }
    }
    getLessonComments();
  }, [lessonId]);

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className="lesson-attach-container">
      {isFileUpload ? (
          <div className="file-upload-loader">
            <LoaderComponent />
          </div>
        ) : (
          <div>
            <div className="comment-block">
              <h3>Коментарии к уроку</h3>
              <TextField
                rows={3}
                variant="outlined"
                label="Оставть свой отзыв"
                multiline
                onChange={(e) => setComment(e.target.value)}
              />

              <div className="upload-image-field">
                <Button variant="outlined" className="attach-file" onClick={chooseFile}>
                  <AttachFileIcon />
                </Button>
                {fileName && (
                  <div className="attached-file-wrapper">
                    <div className="file-wrapper">
                      <CancelIcon className="cancel-file" onClick={cancelAttachFile}/>
                      <InsertDriveFileIcon className="file"/>
                    </div>
                    <p className="file-name">{fileName}</p>
                  </div>
                )}
              </div>
              <input
                type="file"
                style={{ display: 'none' }}
                id={'fake-file-input'}
                onChange={uploadFile}
              />
            </div>
            <Button variant="outlined" className="send-comment" onClick={addLessonComment}>Отправить</Button>
          </div>
        )}


      {(lessonComments ? lessonComments : []).map((item, index) => {
        return (
          <div key={index} className="comment-item">
            <img src={item.avatar[0].url} alt="avatar"/>
            <div className="comment-item-user-info">
              <p className="user-name">
                {item.displayName}
                <span className="comment-date">{trimDate(item.timestamp)}</span>
              </p>
              <p className="comment-text">{item.text}</p>

              {item.attachments && item.attachments.map((item, index) => {
                return (
                  <div key={index}>
                    {item &&
                      <div className="download-file-wrapper">
                        <a href={item.url} style={{}}>
                          <InsertDriveFileIcon className="download-file" />
                        </a>
                        <p className="download-file-text">{item?.name}</p>
                      </div>
                    }
                  </div>
                )
              })
              }
            </div>
          </div>
        )
      })}
    </div>
  )
}
