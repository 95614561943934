import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Teacher } from '../../components/teacher';
import { Student } from '../../components/student';
import firebase from 'firebase';

import './styles.scss';

export const Profile = () => {
  const history = useHistory();
  const userData = JSON.parse(localStorage.getItem('user'));

  const goHome = () => {
    history.push('/')
  };

  const logout = async () => {
    await firebase.auth().signOut();
    localStorage.clear();
    history.push('/home');
  };

  return (
    <div className='profile-container'>
      <div className='header-container'>
        <img src={require('../../assets/img/logo.png')} className='logo' alt="logo" onClick={goHome}/>
        <div className="header-container-buttons">
          <Button variant="outlined"  onClick={() => history.push('/lessons')}>Найти урок</Button>
          {userData && <Button variant="outlined"  onClick={logout}>Выйти</Button>}
        </div>
      </div>
      {userData.type === 'teacher' && (
        <Teacher user={userData} />
      )}
      {userData.type === 'student' && (
        <Student user={userData} />
      )}
    </div>
  );
};
