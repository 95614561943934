import React, { useState } from 'react';
import { Dialog, TextField, Button, InputAdornment, IconButton } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';
import './styles.scss';
import { LoaderComponent } from '../loader';
import { API_URL } from '../constants';

export const BankInfoModal = ({ isModalOpen, onModalCLose }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const userSplitName = user.displayName.split(' ');
  const date = new Date();
  const currentDate = date.toJSON().slice(0,10).replace(/-/g,'.');

  const [bankInfo, setBankInfo] = useState({
    name: userSplitName[0],
    surname: userSplitName[1],
    cardNumber: '',
    birthDate: currentDate,
    cardMonthEnd: '',
    cardYearEnd: '',
    phone: user.telephone ? user.telephone : '',
    city: user.city ? user.city : '',
    country: user.country ? user.country : '',
  });

    const [isLoading, setIsLoading] = useState(false)
    const validDate = +new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const isSubmitDisabled = () => {
        return !bankInfo?.name.length ||
        !bankInfo?.surname.length ||
        !bankInfo?.birthDate ||
        bankInfo?.cardNumber.toString().length !== 16 ||
        !bankInfo?.cardMonthEnd ||
        !bankInfo?.cardMonthEnd.length ||
        bankInfo?.cardMonthEnd.length !== 2 ||
        parseInt(bankInfo?.cardMonthEnd, 10) > 12 ||
        parseInt(bankInfo?.cardMonthEnd, 10) === 0 ||
        !bankInfo?.cardYearEnd ||
        !bankInfo?.cardYearEnd.length ||
        bankInfo?.cardYearEnd.length !== 2 ||
        parseInt(bankInfo?.cardYearEnd, 10) < 21 ||
        (validDate > +new Date(validDate)
                .setFullYear(
                    parseInt('20' + bankInfo.cardYearEnd, 10), parseInt(bankInfo.cardMonthEnd, 10)-1
                )
        );
    };

    const onBankInfoChange = ({ field, value }) => {
      if (field === 'cardNumber') {
         setBankInfo({ ...bankInfo, ...{ [field]: +value } });
        } else {
         setBankInfo({ ...bankInfo, ...{ [field]: value } });
        }
    }

  const addAccountInfo = async () => {
    try {
      setIsLoading(true);
      const res = await axios.put(`${API_URL}/users/${user._id}`, {bankAccountInfo: bankInfo});
      localStorage.setItem('user', JSON.stringify(res.data));
      onModalCLose();
    } catch (err) {
      console.log('Update user bank info error:', err);
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return <LoaderComponent />
  }

  return (
    <Dialog open={isModalOpen} onClose={onModalCLose}>
      <div className="bank-account-modal-wrapper">
        <h3>Введите номер вашей банковской карты чтобы получать оплату за уроки:</h3>

        <TextField
          onChange={(e) => {
            onBankInfoChange({
              field: 'name',
              value: e.target.value
            })
          }}
          label="Имя"
          type='text'
          defaultValue={userSplitName[0]}
        />

        <TextField
          onChange={(e) => {
            onBankInfoChange({
              field: 'surname',
              value: e.target.value
            })
          }}
          label="Фамилия"
          defaultValue={userSplitName[1]}
          type='text'
        />

        <TextField
          onChange={(e) => {
            onBankInfoChange({
              field: 'cardNumber',
              value: e.target.value.split(' ').join('')
            })
          }}
          className="card-number"
          label="Номер карты"
          type='text'
          inputProps={{ maxLength: 19 }}
          onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/(.{4})/g, '$1 ').trim() }}
        />

          <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                  variant="inline"
                  autoOk
                  className="birth-date"
                  disableFuture
                  onChange={(e) => {
                      onBankInfoChange({
                          field: 'birthDate',
                          value: `${moment(e).toDate().getFullYear()}.${moment(e).toDate().getMonth() + 1}.${moment(e).toDate().getDate()}`
                      })
                  }}
                  value={bankInfo && bankInfo.birthDate ? bankInfo.birthDate : currentDate }
                  format="YYYY.MM.DD"
                  label="Дата рождения"
                  InputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                              <IconButton>
                                  <EventIcon />
                              </IconButton>
                          </InputAdornment>
                      ),
                  }}
              />
          </MuiPickersUtilsProvider>

          <div className="card-date">
              <TextField
                  onChange={(e) => {
                      onBankInfoChange({
                          field: 'cardMonthEnd',
                          value: e.target.value
                      })
                  }}
                  label="Месяц окончания действия карточки"
                  type='text'
                  inputProps={{ maxLength: 2 }}
                  onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
              />

              <TextField
                  onChange={(e) => {
                      onBankInfoChange({
                          field: 'cardYearEnd',
                          value: e.target.value
                      })
                  }}
                  label="Год окончания действия карточки"
                  type='text'
                  inputProps={{ maxLength: 2 }}
                  onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
              />
          </div>

        <TextField
          onChange={(e) => {
            onBankInfoChange({
              field: 'phone',
              value: e.target.value
            })
          }}
          label="Номер телефона"
          type='text'
          defaultValue={bankInfo.phone}
        />

        <TextField
          onChange={(e) => {
            onBankInfoChange({
              field: 'city',
              value: e.target.value
            })
          }}
          label="Город"
          type='text'
          defaultValue={bankInfo.city}
        />

        <TextField
          onChange={(e) => {
            onBankInfoChange({
              field: 'country',
              value: e.target.value
            })
          }}
          label="Страна"
          type='text'
          defaultValue={bankInfo.country}
        />

      <Button
          className="add-info-btn"
          variant="outlined"
          onClick={addAccountInfo}
          disabled={isSubmitDisabled()}
      >
          Добавить
      </Button>
      </div>
    </Dialog>
  );
};
