import React, { useEffect, useState } from 'react';
import { Tabs, AppBar, Tab, Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Profile } from '../profile';
import { CreateLesson } from '../createLesson';
import { MyLessons } from '../myLessons';
import { ReviewsList } from '../reviewsList';
import axios from 'axios';
import { API_URL } from '../constants';
import { Calendar } from '../calendar';
import { UsefulTips } from '../usefulTips';

import './styles.scss';

export const Teacher = ({ user }) => {
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [isTabDisabled, setTabDisabled] = useState(false);
  const [teacherStatus, setTeacherStatus] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    if (
      history.location.state &&
      history.location.state.hasOwnProperty('cameFromCalendar') &&
      history.location.state.cameFromCalendar
    ) {
      setValue(2);
      history.replace('/profile', {});
    }

    if (
      history.location.state &&
      history.location.state.hasOwnProperty('lessonData') &&
      history.location.state.lessonData.isLessonClone
    ) {
      setValue(3);
    }

    if (teacherStatus === 'new' || teacherStatus === 'pending') {
      setTabDisabled(true);
      setValue(5);
    }

    async function getTeacherProfile() {
      try {
        const userInfo = await axios.get(`${API_URL}/users/${user._id}`);
        setTeacherStatus(userInfo.data.isApproved);
        localStorage.setItem('user', JSON.stringify(userInfo.data))
      } catch (err) {
        console.log('Get user profile info error', err)
      }
    }
    getTeacherProfile();
  }, [teacherStatus, history, user._id]);

  return (
    <div className='teacher-container'>
      <h2>Страница учителя</h2>

      <div>
        <AppBar position="static">
          <Tabs className='tabs-container' value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Полезные советы" {...a11yProps(0)} />
            <Tab label="Мои Уроки" {...a11yProps(1)} disabled={isTabDisabled} />
            <Tab label="Календарь" {...a11yProps(2)} disabled={isTabDisabled} />
            <Tab label="Создать урок" {...a11yProps(3)} disabled={isTabDisabled} />
            <Tab label="Отзывы" {...a11yProps(4)} disabled={isTabDisabled} />
            <Tab label="Профайл" {...a11yProps(5)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <UsefulTips />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="teacher-profile">
            <MyLessons />
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Calendar />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <CreateLesson
            setTabValue={setValue}
            cloneData={
              history.location.state &&
              history.location.state.hasOwnProperty('lessonData') &&
              history.location.state.lessonData.isLessonClone ? history.location.state : null
            }
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ReviewsList isRating  currentUserId={user._id}/>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Profile setStatus={setTeacherStatus}/>
        </TabPanel>
      </div>
    </div>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}
