import React from 'react';

import './styles.scss';

export const sameDay = (d1, d2) => {
  return new Date(d1).getFullYear() === new Date(d2).getFullYear() &&
    new Date(d1).getMonth() === new Date(d2).getMonth() &&
    new Date(d1).getDate() === new Date(d2).getDate();
};

export const Day = ({ day, userLessons, followedLessons }) => {
  const date = day.getDate();
  const filteredUserClubs = userLessons.length ? userLessons.filter((event) => sameDay(day, event?.startTs)) : [];
  const filteredFollowedClubs = followedLessons.length ? followedLessons.filter((event) => sameDay(day, event?.startTs)) : [];
  return (
    <div className="day-container">
      <div className="day-number-container">
        <p className="day-number-text">{date}</p>
      </div>

      {
        !!filteredUserClubs.length && (
          <div className="events-container">
            {
              filteredUserClubs.map((e, i) => (
                <div className="event" key={`${date}-${i}`} />
              ))
            }
          </div>
        )
      }

      {
        !!filteredFollowedClubs.length && (
          <div className="events-container">
            {
              filteredFollowedClubs.map((e, i) => (
                <div className="event" key={`${date}-${i}`} />
              ))
            }
          </div>
        )
      }
    </div>
  );
};
