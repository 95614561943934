import React, {useEffect, useState} from 'react';
import DayPicker from 'react-day-picker';
import {Day, sameDay} from './day';
import axios from 'axios';
import {API_URL} from '../constants';
import {useHistory} from 'react-router-dom';
import MomentLocaleUtils from 'react-day-picker/moment';
import { trimDate } from '../../utils';
import { LoaderComponent } from '../loader';
import 'moment/locale/ru';

import './styles.scss';
import 'react-day-picker/lib/style.css';

export const Calendar = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const history = useHistory();
  const eventDay = new Date();
  const [isLoading, setIsLoading] = useState(false);
  const [followed, setFollowed] = useState([]);
  const [lessons, setUserLessons] = useState([]);
  const [isLessonsListShown, setLessonsListShown] = useState(true);
  const [displayedLessons, setDisplayedLessons] = useState([]);

  const renderDay = (day) => {
    return <Day day={day} userLessons={lessons} followedLessons={followed}/>;
  };

  const onChangeDay = (day) => {
    setLessonsListShown(true);
    let displayUserLessons;
    if (user.type === 'student') {
      displayUserLessons = followed.filter((club) => sameDay(day, club.startTs));
    } else {
      displayUserLessons = lessons.filter((club) => sameDay(day, club.startTs));
    }
    setDisplayedLessons(displayUserLessons);
  };

  const goToLessonPage = (id) => {
    history.push(`/lesson/${id}`, { cameFromCalendar: true })
  }

  useEffect(() => {
    if (user.type === 'teacher') {
      async function getTeacherLessons() {
        try {
          setIsLoading(true);
          let lessonsData = await axios.get(`${API_URL}/user-lessons/${user._id}`);
          setUserLessons(lessonsData.data);
        } catch (err) {
          console.log('Error', err);
        } finally {
          setIsLoading(false);
        }
      }

      getTeacherLessons();
    } else {
      async function getUserLessons() {
        try {
          setIsLoading(true);
          let lessonsData = await axios.post(`${API_URL}/lessons-follow`, {follow: user.follow});
          setFollowed(lessonsData.data);
        } catch (err) {
          console.log('Get user events error', err);
        } finally {
          setIsLoading(false);
        }
      }

      getUserLessons();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <LoaderComponent/>
  }

  return (
    <div className='calendar-wrapper'>
      <DayPicker
        localeUtils={MomentLocaleUtils}
        locale="ru"
        firstDayOfWeek={1}
        selectedDays={eventDay}
        onDayClick={onChangeDay}
        renderDay={renderDay}/>

      <div className='lessons-list-container'>
        {isLessonsListShown &&
        displayedLessons.map((el, i) => {
          return (
            <div key={el._id} className='lesson-element' onClick={() => goToLessonPage(el._id)}>
              <img src={el.images[0].url} alt="avatar"/>
              <div className='lesson-info'>
                <h4>{el.name}</h4>
                <div className='lesson-info-table'>
                  <p>{!el.ageGroupFrom && !el.ageGroupTo ?
                    'Все возраста' :
                    `${el.ageGroupFrom} - ${el.ageGroupTo} лет`
                  }
                  </p>

                  <p>{el.lessonSizeFrom === 1 && el.lessonSizeTo === 1 ?
                    'Индивидуальный урок' :
                    `${el.lessonSizeFrom} - ${el.lessonSizeTo} чел`
                  }
                  </p>

                  <p>{trimDate(el.startTs)}</p>
                  <p>{el.price === 0 ? 'Бесплатно' : `${el.price}$`}</p>
                </div>
              </div>
            </div>
          )
        })}
      </div>

    </div>
  );
};
