import React, {useEffect, useState} from 'react';
import { Button } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import axios from 'axios';
import { API_URL } from '../../components/constants';

import './styles.scss';

export const PaymentStatusPage = () => {
  const history = useHistory();
  const [paymentStatus, setPaymentStatus] = useState(undefined);
  const params = useLocation().search.split('=');
  const user = JSON.parse(localStorage.getItem('user'));

  const goHome = () => {
    history.push('/')
  }
  const goToLessonPage = () => {
    const lessonId = JSON.parse(localStorage.getItem('latestLesson'));
    history.push(`/lesson/${lessonId}`);
  }

  const getUserInfo = async () => {
    try {
      const userInfo = await axios.get(`${API_URL}/users/${user._id}`);
      localStorage.setItem('user', JSON.stringify(userInfo.data));
    } catch (err) {
      console.log('Get user info error:', err)
    }
  }

  useEffect(() => {
    if (params[1] === 'true') {
      getUserInfo();
      setPaymentStatus(true);
    } else {
      setPaymentStatus(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='payment-status-container'>
      <div className='header-container'>
        <img src={require('../../assets/img/logo.png')} className='logo' alt="logo" onClick={goHome}/>
      </div>
      {paymentStatus ?
        <div className="status-info-container">
          <CheckIcon className="status-icon-correct" />
          <p>Ваш платеж успешно обработан</p>
        </div> :
        <div className="status-info-container">
          <ClearIcon className="status-icon-fail" />
          <p>Ваш платеж обработан с ошибкой</p>
        </div>
      }
      <Button variant="outlined" onClick={goToLessonPage} className="go-to-lesson-btn">
        Вернуться на страницу урока
      </Button>
    </div>
  );
};
