import React, { useEffect, useState } from 'react';
import { Box, Button, TextField } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../components/constants';
import { LoaderComponent } from '../../components/loader';
import { ReviewsList } from '../../components/reviewsList';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import firebase from 'firebase';

import './styles.scss';

const googleProvider = new firebase.auth.GoogleAuthProvider();

export const TeacherProfilePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [rating, setRating] = useState(0);
  const [teacherAverageRating, setTeacherAverageRating] = useState(0);
  const [isRatingDisabled, setIsRatingDisabled] = useState(null);
  const [comment, setComment] = useState('');
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('user'));
  const userId = history.location.pathname.split('/')[2];

  const goHome = () => {
    history.push('/home')
  };

  const getUserProfileInfo = async () => {
    try {
      setIsLoading(true);
      const userInfo = await axios.get(`${API_URL}/users/${userId}`);
      setUserData(userInfo.data);

      const averageTeacherRating = userInfo.data.rating;
      let avr = 0;
      for (const rate of averageTeacherRating) {
        avr = avr + +rate.value;
      }

      setTeacherAverageRating(avr / averageTeacherRating.length);

      const isRatingDisabled = userInfo.data.rating.filter((item) => {
        return item.studentId === user._id;
      });

      setRating(isRatingDisabled[0].value);
      setIsRatingDisabled(isRatingDisabled);

    } catch (err) {
      console.log('Get user profile info error', err)
    } finally {
      setIsLoading(false);
    }
  };

  const onCommentAdd = async () => {
    try {
      const commentData = {
        comments: [...userData.comments]
      };
      const commentObject = {
        author: user._id,
        timestamp: +new Date(),
        text: comment
      };
      commentData.comments.push(commentObject);

      setIsLoading(true);
      await axios.put(`${API_URL}/users/${userId}`, commentData);
      await getUserProfileInfo();
    } catch (err) {
      console.log('Add comment error:')
    } finally {
      setIsLoading(false);
    }
  };

  const setUserRating = async (event) => {
    const newValue = event.target.value;
    const ratingData = {
      rating: [...userData.rating]
    }

    const ratingObject = {
      studentId: user._id,
      value: newValue,
      timestamp: +new Date()
    }

    ratingData.rating.push(ratingObject);

    try {
      setIsLoading(true);
      setRating(newValue);
      const result = await axios.put(`${API_URL}/users/${userId}`, ratingData);
      await getUserProfileInfo();

      const isRatingDisabled = result.data.rating.filter((item) => {
        return item.studentId === user._id;
      });
      setRating(isRatingDisabled[0].value);
      setIsRatingDisabled(isRatingDisabled);

    } catch (err) {
      console.log('Set user rating error:', err);
    } finally {
      setIsLoading(false);
    }
  }

  const goBack = () => {
    history.goBack();
  };

  const login = async () => {
    try {
      setIsLoading(true);
      const { user } = await firebase.auth().signInWithPopup(googleProvider);

      const result = await axios.post(`${API_URL}/login`, {
        email: user.email,
        displayName: user.displayName,
        fireId: user.uid,
        avatar: {
          url: user.photoURL,
        },
      });

      localStorage.setItem('user', JSON.stringify(result.data));
      history.push(!result.data.type ? `/person-type/${result.data._id}` : '/profile');
    } catch (error) {
      alert(`Login error.` + (error.message ? ` ${error.message}` : ''));
    } finally {
      setIsLoading(false);
    }
  };

  const goToProfile = () => {
    history.push(user.type ? '/profile' : `/person-type/${user._id}`);
  };

  useEffect(() => {
    getUserProfileInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className='teacher-profile-wrapper'>
      <div className="teacher-header-container">
        <img src={require('../../assets/img/logo.png')} className='logo' alt="logo" onClick={goHome}/>
        <div className="teacher-header-container-buttons">
          <Button variant="outlined"  onClick={() => history.push('/lessons')}>Найти урок</Button>
          {
            user ?
              <Button variant="outlined" onClick={goToProfile}>Мой профиль</Button> :
              <Button variant="outlined"  onClick={login}>Войти</Button>
          }
        </div>
      </div>
      <Button variant="outlined" className="go-back-btn" onClick={goBack}>
        <ArrowBackIcon />
      </Button>
      <div className="video-main-info-wrapper">
        <div className="video-heading-wrapper">
          <h3>{userData?.displayName}</h3>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <Rating
                disabled={true}
                name="disabled"
                value={teacherAverageRating ? teacherAverageRating.toFixed(0) : 0}
            />
          </Box>
        </div>

        <div className="video-wrapper">


          <video
              poster={userData && userData.avatar[0].url}
              src={userData && userData.video && userData.video.url}
              controls
              className="video"
          />

        <div className="teacher-profile-main-info">
          <div className="info-wrapper">

            <p>Образование: {userData?.education}</p>
            <p>О себе: {userData?.bio}</p>
          </div>


        </div>
        </div>
      </div>
      <div className="teacher-profile-additional-info">




        {
          userData?.comments.length > 0 && <h3>Отзывы:</h3>
        }
        <ReviewsList isRating={false}/>
      </div>
      <div className="rate-teacher">
        {user && user.type === 'student' && (
            <Box component="fieldset" mb={3} borderColor="transparent">
              {!!isRatingDisabled && user.type === 'student' ? <h3>Ваша оценка учителю</h3> : <h3>Оцените учителя</h3>}
              <Rating
                  className="rating"
                  disabled={!!isRatingDisabled}
                  name="simple-controlled"
                  value={rating}
                  onChange={setUserRating}
              />
            </Box>
        )}
      </div>

      {user && user.type === 'student' && (
          <div className="review-wrapper">
            <h3>Добавить отзыв о учителе:</h3>
            <div className="review-form">
            <TextField
                className="review-text-field"
                rows={3}
                variant="outlined"
                label="Оставть свой отзыв"
                multiline
                onChange={(e) => setComment(e.target.value)}
            />
            <Button variant="outlined" className="send-comment" onClick={onCommentAdd}>Отправить</Button>
            </div>
          </div>
      )}
    </div>
  );
};
