import React, { useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { API_URL, CURR_RATE_URL } from '../../components/constants';
import { LoaderComponent } from '../../components/loader';
import userAvatar from '../../assets/img/teacher_avatar_mok.png';
import { Timer } from '../../components/Timer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ReviewsListWithAttach } from '../../components/reviewListWithAttachFiles';
import Rating from '@material-ui/lab/Rating';
import firebase from 'firebase';

import './styles.scss';

const googleProvider = new firebase.auth.GoogleAuthProvider();
// const DAYS_BEFORE_UNFOLLOW = 1000 * 60 * 60 * 24 * 5; // 5 days
const DAYS_BEFORE_UNFOLLOW = 1000 * 60 * 60 * 5; // 5 hours

export const LessonPage = () => {
  let isCounterShowed = true;
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('user'));
  const unchangedUser = JSON.parse(localStorage.getItem('user'));
  const { id } = useParams();
  const [isFollowed, setIsFollowed] = useState(false);
  const [lesson, setLesson] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [clubState, setClubState] = useState(undefined);
  const [rating, setRating] = useState(0);
  const [isButtonShowed, setIsButtonShowed] = useState(false);
  const [currency, setCurrency] = useState({});
  const [historyState, setHistoryState] = useState({});
  const lessonId = history.location.pathname.split('/')[2];
  const interkassaPaymentNumber = (lessonId + (user ? user._id : +new Date())).substr(0, 32);

  const averageRating = (ratingData) => {
    let avr = 0;
    for (const rate of ratingData) {
      avr = avr + +rate.value;
    }
      return avr / ratingData.length;
  }

  const getLessonById = async () => {
    try {
      setIsLoading(true);
      const result = await axios.get(`${API_URL}/lessons/${lessonId}`);
      setLesson(result.data);
      const newUserObj = Object.assign({}, user, { userLastLessonId: lesson._id })
      if (user) {
        localStorage.setItem('user', JSON.stringify(newUserObj));
      }
      if (result.data.rating.length > 0) {
        setRating(averageRating(result.data.rating));
      }
    } catch (err) {
      console.log('Get lesson by id error', err);
    } finally {
      setIsLoading(false);
    }
  };

  const unfollowLesson = async () => {
    if (+new Date(lesson.startTs) - DAYS_BEFORE_UNFOLLOW <= +new Date()) {
      alert('Вы можете отписаться от урока не позже 5 часов до его начала');
      return;
    }

    try {
      setIsLoading(true);
      user.follow = user.follow.filter((el) => {
        return el !== id
      });
      await axios.post(`${API_URL}/unfollow/?userId=${user._id}&clubId=${id}`, user);
      setIsFollowed(false);
      await getLessonById();
    } catch (err) {
      console.log('Unfollow lesson error:', err)
    } finally {
      setIsLoading(false)
    }
  };

  const startFollow = async (e) => {
    if (!user) {
      alert('Вы доджны авторизоватся что бы иметь возможность подписыватся на уроки');
      return;
    }

    if (+new Date() >= (+new Date(lesson.startTs) - 14400000)) {
      alert('Вы можете подписаться на урок не позже 4 часов до его начала');
      return;
    }

    try {
      setIsLoading(true);
      user.follow.push(id);
      const resp = await axios.put(`${API_URL}/follow/?userId=${user._id}&clubId=${id}`, {user, unchangedUser, lesson});
      setIsFollowed(resp.data.follow.includes(id));
      localStorage.setItem('user', JSON.stringify(resp.data));
      history.push('/profile');
    } catch (err) {
      if (+err.message.split('code')[1] === 400) {
        alert('В это время у Вас будет другой урок');
      }
      console.log('Follow lesson error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setHistoryState(history.location.state);
    localStorage.removeItem('latestLesson');
    if(user && user.follow && user.follow.includes(id)) {
      setIsFollowed(true);
    }
    getLessonById();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchCurrency = async () => {
      const result = await axios.get(`${API_URL}/currency`, {
        headers: {
          Accept: 'application/json'
        }
    });
      const { USD } = result.data.data;
      setCurrency(USD.UAH.out);
    };
    if (lesson.price > 0) {
      fetchCurrency();
    }
  }, [lesson])

  const goToTeacherProfile = () => {
    history.push(`/teacher/${lesson.host._id}`)
  };

  const copyLesson = () => {
    const lessonData = Object.assign({}, { lesson }, { isLessonClone: true })
    history.push(`/profile/${lesson.host._id}`, { lessonData });
  };

  const saveLocalUserFollows = () => {
    user.follow.push(id);
    localStorage.setItem('latestLesson', JSON.stringify(id));
  };

  const getButtonToClick = () => {
    let button = null;

    if (clubState === 'finished') {
      button = (
        <div className="start-follow-button disabled">
          <p className="start-follow-button-text">
            Закончен
          </p>
        </div>
      );
    }

    // Before 3 hours until lesson start, check for minimum followers
    else if ((lesson.followers?.length < lesson.lessonSizeFrom) && ((+new Date(lesson.startTs) - +new Date()) < 3600000 * 3)) {
      isCounterShowed = false;

      button = (
          <div className="start-follow-button disabled">
            <p className="start-follow-button-text">
              Урок не набрал необходимое количество учеников
            </p>
          </div>
      );
    }

    else if (user && lesson.host?._id === user._id) {
      if (clubState === 'in_process' || (clubState === 'pending' && ((+new Date(lesson.startTs) - +new Date()) < 60 * 60 * 1000)) || isButtonShowed) {
        button = (
          <div className={`start-follow-button`} onClick={() => window.open(lesson.event.startUrl, '_blank')}>
            <Button variant="outlined">
              Начать урок
            </Button>
          </div>
        );
      }
    } else if (isFollowed) {
        if (clubState === 'in_process' || (clubState === 'pending' &&
          new Date(lesson.startTs) - new Date() < 60 * 60 * 1000
        )) {
        button = (
          <div className="start-follow-button" onClick={() => window.open(lesson.event.joinUrl, '_blank')}>
            <Button variant="outlined">
              Присоеденится
            </Button>
          </div>
        );
      }
    } else if ((lesson.followers?.length < lesson.lessonSizeTo) && user?.type !== 'teacher') {
      button = (
        <div className="start-follow-button">
          {lesson.price > 0 ? (
            <form name="payment" method="post" action="https://sci.interkassa.com/" acceptCharset="UTF-8">
              <input type="hidden" name="ik_co_id" value="601063dc716f6b14b46b8971" />
              <input type="hidden" name="ik_pm_no" value={interkassaPaymentNumber} />
              <input type="hidden" name="ik_am" value={
                (lesson && lesson.ik_ps_price && lesson.ik_ps_price.split('.')[0]) ||
                (lesson && currency && (lesson.price * currency).toFixed(0))
              } />
              <input type="hidden" name="ik_cur" value="uah" />
              <input type="hidden" name="ik_desc" value={`Оплата урока ${lesson.name} _${user ? user._id : '0001'}`} />
              <Button variant="outlined" className="payed-subscribe-btn">
                <input
                  type="submit"
                  value="Подписаться на урок"
                  onClick={(user && (+new Date() <= (+new Date(lesson.startTs) - 14400000)))
                    ?
                      () => saveLocalUserFollows()
                    :
                      (e) => {
                        e.preventDefault();
                        if (!user) {
                          alert('Вы должны авторизоватся что бы иметь возможность подписыватся на уроки');
                          return;
                        }
                        alert('Вы можете подписаться на урок не позже 4 часов до его начала');
                      }
                  }
                />
              </Button>
            </form>
          ) : (
            <Button variant="outlined" onClick={startFollow}>
              Подписаться на урок
            </Button>
          )}
        </div>
      );
    }

    return button;
  };

  const deleteLesson = async () => {
    if (lesson.comments?.length) {
      alert('Вы не можете отменить этот урок, так как у него есть отзывы.');
      return;
    }
    if (lesson.rating?.length) {
      alert('Вы не можете отменить этот урок, так как ему ставили рейтинг.');
      return;
    }
    try {
      setIsLoading(true);
      await axios.delete(`${API_URL}/lessons/${lesson._id}`);
      history.push('/profile');
    } catch (err) {
      console.log('Delete lesson error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const goBack = () => {
    history.goBack();
  };

  const goToCalendar = () => {
    history.push('/profile', { cameFromCalendar: true });
  };

  const goHome = () => {
    history.push('/home');
  };

  const login = async () => {
    try {
      setIsLoading(true);
      const { user } = await firebase.auth().signInWithPopup(googleProvider);

      const result = await axios.post(`${API_URL}/login`, {
        email: user.email,
        displayName: user.displayName,
        fireId: user.uid,
        avatar: {
          url: user.photoURL,
        },
      });

      localStorage.setItem('user', JSON.stringify(result.data));
      history.push(!result.data.type ? `/person-type/${result.data._id}` : '/profile');
    } catch (error) {
      alert(`Login error.` + (error.message ? ` ${error.message}` : ''));
    } finally {
      setIsLoading(false);
    }
  };

  const goToProfile = () => {
    history.push(user.type ? '/profile' : `/person-type/${user._id}`);
  };

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className='lesson'>
      <div className="lesson-header-container">
        <img src={require('../../assets/img/logo.png')} className='logo' alt="logo" onClick={goHome}/>
        <div className="lesson-header-container-buttons">
          <Button variant="outlined"  onClick={() => history.push('/lessons')}>Найти урок</Button>
          {
            user ?
              <Button variant="outlined" onClick={goToProfile}>Мой профиль</Button> :
            <Button variant="outlined"  onClick={login}>Войти</Button>
          }
        </div>
      </div>

      <Button variant="outlined" className="go-back-btn" onClick={historyState && historyState.cameFromCalendar ? goToCalendar : goBack}>
        <ArrowBackIcon />
      </Button>
      <div className="buttons">
        {user?.type === 'teacher' && user?._id === lesson?.host?._id && (
          <Button variant="outlined" onClick={copyLesson}>Повторить урок</Button>
        )}

        {lesson?.followers &&
        (+new Date(lesson.startTs) > +new Date()) &&
        !lesson?.followers?.length
        && user?._id === lesson?.host?._id
        && <Button variant="outlined" onClick={deleteLesson}>Отменить урок</Button>
        }

        {+new Date(lesson.startTs) > +new Date() && isFollowed && (
          <Button variant="outlined" onClick={unfollowLesson}>
            Отменить подписку
          </Button>
        )}

        <div className="info-buttons-container">
          {user && user.type === 'student' &&
          lesson.followers &&
          lesson.followers.length >= lesson.lessonSizeTo &&
          lesson.lessonSizeTo &&
          lesson.lessonSizeTo &&
          <Button variant="outlined"  disabled className="subscribe-lesson-button">Класс заполнен</Button>
          }

        </div>
        <div >{getButtonToClick()}</div>
      </div>
      {user && user.type === 'teacher' && lesson.host && user._id === lesson.host._id && (
        <div>
          <h3>Количество подписавшихся: {lesson.followers.length}</h3>
        </div>
      )}

      {
        isCounterShowed && <Timer
          startTs={lesson.startTs}
          duration={lesson.duration}
          clubState={clubState}
          changeClubState={setClubState}
          setIsButtonShowed={setIsButtonShowed}
        />
      }

      <div className="lesson-info">
        <div className="lesson-description">
          <h2>{lesson?.name}</h2>
          <div className="lesson-image">
            <img src={lesson.images && lesson.images[0] ? lesson.images[0].url : userAvatar} alt=""
                 referrerPolicy="no-referrer"/>
            {clubState === 'finished' && (
                <Box component="fieldset" mb={3} borderColor="transparent">
                  <Rating
                      disabled={true}
                      name="simple-controlled"
                      value={rating}
                      size='large'
                  />
                </Box>
            )}
          </div>

        </div>
        <div className="long-description">
          <h3>
            Описание урока
          </h3>
          <p>{lesson.lessonFullDesc}</p>
        </div>

      </div>

      <table className="small-info">
        <tbody>

        <tr>
          <td>
            Длительность урока
          </td>

          <td>
            Размер группы
          </td>

          <td>
            Возраст участников
          </td>

          <td>
            Стоимость одного занятия
          </td>
        </tr>
        <tr>
          <td className="border-bottom">
            {lesson.duration} мин
          </td>

          <td className="border-bottom">{lesson.lessonSizeFrom === 1 && lesson.lessonSizeTo === 1 ?
              'Индивидуальный урок' :
              `${lesson.lessonSizeFrom} - ${lesson.lessonSizeTo} чел`
          }
          </td>

          <td className="border-bottom">{!lesson.ageGroupFrom && !lesson.ageGroupTo ?
              'Все возраста' :
              `${lesson.ageGroupFrom} - ${lesson.ageGroupTo} лет`
          }
          </td>

          <td className="border-bottom">$ {lesson.price}</td>
        </tr>

        </tbody>
      </table>
      <div className="lesson-teacher">
        <img
            src={lesson.host?.avatar[0].url ? lesson.host.avatar[0].url : userAvatar}
            alt="avatar"
            referrerPolicy="no-referrer"
            className="avatar"
        />
        <h4 onClick={goToTeacherProfile}>{lesson.host?.displayName}</h4>
      </div>
      <div className="greetings-need-wrapper">
        {isFollowed  && <p>{lesson.congratsMessage}</p>}
        <br/>
        <br/>
        {lesson.lessonItems  && (
          <div className="long-description">
            <h3>
              Список материалов, которые понадобятся на уроке
            </h3>
            <p>{lesson.lessonItems}</p>
          </div>
        )}
      </div>

      {user?._id === lesson.host?._id || (lesson.followers || []).includes(user?._id) ? (
        <ReviewsListWithAttach lessonData={lesson} lessonId={lessonId} getLessonInfo={getLessonById}/>
      ): null }
      </div>

  );
};
