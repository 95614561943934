import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MyLessons } from '../../components/myLessons';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import firebase from 'firebase';
import axios from 'axios';
import {API_URL} from '../../components/constants';
import { LoaderComponent } from '../../components/loader';

import './styles.scss';

const googleProvider = new firebase.auth.GoogleAuthProvider();

export const Lessons = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const goHome = () => {
    history.push('/home');
  };

  const goBack = () => {
    history.goBack();
  };

  const login = async () => {
    try {
      setIsLoading(true);
      const { user } = await firebase.auth().signInWithPopup(googleProvider);

      const result = await axios.post(`${API_URL}/login`, {
        email: user.email,
        displayName: user.displayName,
        fireId: user.uid,
        avatar: {
          url: user.photoURL,
        },
      });

      localStorage.setItem('user', JSON.stringify(result.data));
      history.push(!result.data.type ? `/person-type/${result.data._id}` : '/profile');
    } catch (error) {
      alert(`Login error.` + (error.message ? ` ${error.message}` : ''));
    } finally {
      setIsLoading(false);
    }
  };

  const goToProfile = () => {
    history.push( '/profile');
  };

  if (isLoading) {
    return <LoaderComponent />
  }

  return (
    <div className="lessons-container">
      <div className='header-container'>
        <img src={require('../../assets/img/logo.png')} className="logo" alt="logo" onClick={goHome}/>
        {
          user ?
            <Button variant="outlined" onClick={goToProfile}>Мой профиль</Button> :
            <Button variant="outlined"  onClick={login}>Войти</Button>
        }
      </div>
      <Button variant="outlined" className="go-back-btn" onClick={goBack}>
        <ArrowBackIcon />
      </Button>
      <MyLessons />
    </div>
  );
};
