import React, { useState, useCallback, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Modal } from 'react-responsive-modal';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { API_URL } from '../constants';
import { LoaderComponent } from '../loader';

import '../modalStyles.scss';
import './styles.scss';

const pixelRatio = 4;

const chooseFile = (images) => {
  if (images.length >= 1) {
    alert('Вы можете добавить только одну картинку');
    return;
  }
  document.getElementById('fake-file-input').value = null;
  document.getElementById('fake-file-input').click();
};

const getResizedCanvas = (canvas, newWidth, newHeight) => {
  const tmpCanvas = document.createElement('canvas');
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext('2d');
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
};

export const UploadMedia = ({ images, setImages, isLessonCloned, isLessonAvatar = false }) => {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 0, height: 0, aspect: isLessonAvatar ? 10/6 : 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [open, setOpened] = useState(false);
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const openModal = () => {
    setOpened(true);
  };

  const onCloseModal = () => {
    setOpened(false);
  };

  // useEffect(() => {
  //     setCrop({
  //       unit: '%',
  //       width: 0,
  //       height: 0,
  //       aspect: isLessonAvatar ? 10/6 : 1
  //     })
  // }, [isLessonAvatar])

  const onSelectFile = e => {
    const file = e.target.files[0];
    const sizeInMB = (file.size / (1024*1024)).toFixed(2);
    if (e.target.files && e.target.files.length > 0) {
      if (sizeInMB > 5) {
        alert('Изображение превышает размер 5 МБ');
        return;
      }
      setFileName(e.target.files[0].name);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setUpImg(reader.result);
        openModal();
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const uploadImage = async (newFile) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', newFile);
      const imageResult = await axios.post(`${API_URL}/images`, formData);
      if (!images.length) {
        const clubImages = { images: [...images, Object.assign(imageResult.data, { primary: true })]};
        if (history?.location?.state?.id) {
          await axios.put(`${API_URL}/lessons/${history.location.state.id}`, clubImages);
        }
        setImages(clubImages.images);
      } else {
        const clubImages = { images: [...images, Object.assign(imageResult.data, { primary: false })]};
        if (history?.location?.state?.id) {
          await axios.put(`${API_URL}/lessons/${history.location.state.id}`, clubImages);
        }
        setImages(clubImages.images);
      }
    } catch (e) {
      console.log('Upload image error', e);
    } finally {
      setIsLoading(false);
    }
  };

  const onAddImage = async () => {
    if (!completedCrop || !previewCanvasRef.current) {
      return;
    }

    if (images.length >= 1) {
      alert('You can add only one picture');
      return;
    }

    try {
      setIsLoading(true);
      const canvas = await getResizedCanvas(previewCanvasRef.current, completedCrop.width, completedCrop.height);

      await canvas.toBlob(
        blob => {
          const newFile = blob;
          newFile.lastModifiedDate = new Date();
          newFile.name = fileName;
          const croppedImageUrl = window.URL.createObjectURL(blob);
          const newImages = Object.assign([], images);
          const newImageData = { url: croppedImageUrl, name: fileName };
          newImages.push(newImageData);
          setCrop({
            unit: '%',
            width: 0,
            height: 0,
            aspect: isLessonAvatar ? 10/6 : 1
          });
          setOpened(false);
          uploadImage(new File([newFile], fileName, {
            type: 'img/png'
          }));
        }
      );
    } catch (e) {
      console.log('Add image error:', e);
    } finally {
      setIsLoading(false);
    }
  };

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const deletePhoto = async (img) => {
    const newImgArr = [...[], ...images];
    const filteredArray = newImgArr.filter(clubImage => clubImage !== img);
    if (isLessonCloned) {
      setImages(filteredArray);
      return;
    }
    const imageFireId = img?.url?.split('/')[9]?.split('_')[0];
    const imageFireName = `${imageFireId}_${img.name}`;
    if (!images[0].name) {
      setImages(filteredArray);
      return;
    }

    if (filteredArray.length) {
      filteredArray[0].primary = true;
    }

    try {
      setIsLoading(true);
      await axios.delete(`${API_URL}/images/${imageFireName}`);
      if (history.location.pathname.split('/')[1] === 'edit-club') {
        await axios.put(`${API_URL}/lessons/${history.location.state.id}`, { images: filteredArray });
      }
      setImages(filteredArray);
    } catch (err) {
      console.log('Delete photo error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const setAsPrimary = async (imgObj, imagesArray) => {
    await [...imagesArray].forEach((el) => {
      el.primary = imgObj === el;
      return imagesArray;
    });
    if (history.location.pathname.split('/')[1] === 'edit-club') {
      const updatedClub = await axios.put(`${API_URL}/lessons/${history.location.state.id}`, {images: imagesArray});
      setImages(updatedClub.data.images);
    } else {
      const newArr = [...imagesArray];
      setImages(newArr);
    }
  };

  if (isLoading) {
    return <div className="upload-media-loader"><LoaderComponent /></div>;
  }

  return (
    <div className="upload-media-wrapper">
      <div className="upload-media-container">
        <div className="upload-image-field" onClick={() => chooseFile(images)}>
          <img src={require('../../assets/img/upload.svg')} alt="upload" className={'upload-img'}/>
        </div>

        <input accept="image/*" type="file" style={{ display: 'none' }} id={'fake-file-input'} onChange={onSelectFile} name="img"/>

        <Modal open={open} onClose={onCloseModal}>
          <p className={'modal-title'}>Выберите область</p>
          <div className={'crop-container'}>
            <ReactCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={c => setCrop(c)}
              onComplete={c => setCompletedCrop(c)}
            />
          </div>

          <div style={{ display: 'none' }}>
            <canvas
              ref={previewCanvasRef}
              style={{
                width: completedCrop?.width ?? 0,
                height: completedCrop?.height ?? 0
              }}
            />
          </div>

          <div className={'buttons-container'}>
            <div className={'button'} onClick={onCloseModal}>
              <p className={'button-text'}>Закрыть</p>
            </div>
            <div className={'button'} onClick={onAddImage}>
              <p className={'button-text'}>Добавить картинку</p>
            </div>
          </div>
        </Modal>
      </div>
      {
        !!images?.length && (
          <div className="images-container">
            {
              images.map((img, i) => {
                return (
                  <div className="card" key={i}>
                    <img src={require('../../assets/img/wrong.svg')}
                         className="card-delete"
                         onClick={() => deletePhoto(img)}
                         alt="delete"/>
                         <img
                           alt="Crop"
                           src={img.url}
                           className="cropped-image"
                           onClick={() => setAsPrimary(img, images)}
                         />
                  </div>
                );
              })
            }
          </div>
        )
      }
    </div>
  );
};
