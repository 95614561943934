import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyBY-3An1I2UPeRBlmGkUqzrAr-_AKj7NfQ",
  // authDomain: "lessonslab.com",//TODO change url
  authDomain: "lessonslab.firebaseapp.com",
  databaseURL: "https://lessonslab.firebaseio.com",
  projectId: "lessonslab",
  storageBucket: "lessonslab.appspot.com",
  messagingSenderId: "475144914267",
  appId: "1:475144914267:web:6fa3ac2a85485cb71e467e"
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
