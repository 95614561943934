import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import { API_URL } from '../../components/constants';
import { LoaderComponent } from '../../components/loader';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import './styles.scss';

export const PersonType = () => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('user'));
  const [isLoading, setIsLoading] = useState(false);

  const goHome = () => {
    history.push('/home')
  }

  const chooseRole = async (userRole) => {
    const { _id } = user;
    let result;
    try {
      setIsLoading(true);
      const userData =  await axios.get(`${API_URL}/users/${_id}`);
      if (userRole === 'teacher' && !userData.hasOwnProperty('isApproved')) {
        result = await axios.put(`${API_URL}/users/${_id}`, {
          type: userRole,
          isApproved: 'new'
        });
      } else {
        result = await axios.put(`${API_URL}/users/${_id}`, {
          type: userRole
        });
      }
      localStorage.setItem('user', JSON.stringify(result.data));
      history.push('/profile');
    } catch (error) {
      console.log('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const goBack = () => {
    history.goBack();
  }

  if (isLoading) {
    return <LoaderComponent />
  }

  return (
    <div className='person-type-container'>
        <img src={require('../../assets/img/logo.png')} className='logo' alt="logo" onClick={goHome}/>
        <div className='role-choose-container'>
          <Button variant="outlined" className="go-back-btn" onClick={goBack}>
            <ArrowBackIcon />
          </Button>
          <p>Вы хотите зарегистрироваться, как:</p>
          <div className='buttons-container'>
            <div className='type-btn'>
              <img
                src={require('../../assets/img/girl_comp.jpg')}
                className='person-type-image'
                alt="Ученик"
              />
              <Button variant="outlined" onClick={() => chooseRole('student')}>
                Ученик
              </Button>
            </div>
            <div className='type-btn'>
              <img
                src={require('../../assets/img/teacher_lady.jpg')}
                className='person-type-image'
                alt="Учитель"/>
              <Button variant="outlined" onClick={() => chooseRole('teacher')}>Учитель</Button>
            </div>
          </div>
        </div>
    </div>
  );
};
