import React, { useEffect, useRef, useState } from 'react';
import { TextField, Button, MenuItem } from '@material-ui/core';
import axios from 'axios';
import { API_URL, TOPICS } from '../constants';
import { LoaderComponent } from '../loader';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { UploadMedia } from '../uploadMedia';
import firebase from 'firebase';
import { useHistory } from 'react-router-dom';

import './styles.scss';

export const Profile = ({ setStatus }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const { _id } = user;
  const history = useHistory();
  const [state, setState] = useState(user);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [topics, setTopics] = useState([]);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const el = useRef();

  const onChangeUser = ({ field, value }) => {
    setState({ ...state, ...{ [field]: value } });
  };

  const handleChange = async (e) => {
    try {
      setIsVideoLoading(true);
      const file = e.target.files[0];
      const sizeInMB = (file.size / (1024*1024)).toFixed(2);
      if (sizeInMB > 100) {
        alert('Видео превышвет 100 МБ');
        return;
      }

      const formData = new FormData();
      formData.append('file', file);
      const res = await axios.post(`${API_URL}/images`, formData);
      const updatedUser = Object.assign({}, state, { video: res.data });
      setState(updatedUser)
    } catch (err) {
      console.log('Upload video error:', err);
    } finally {
      setIsVideoLoading(false);
    }
  };

  const getUserProfileInfo = async () => {
    try {
      setIsLoading(true);
      const userInfo = await axios.get(`${API_URL}/users/${_id}`);
      setState(userInfo.data);
      setTopics(userInfo.data.topics);
      setImages(userInfo.data.avatar);
      if (userInfo.data.type === 'teacher') {
        setStatus(userInfo.data.isApproved);
      }
      localStorage.setItem('user', JSON.stringify(userInfo.data))
    } catch (err) {
      console.log('Get user profile info error', err)
    } finally {
      setIsLoading(false);
    }
  };

  const updateUser = async () => {
    if (!state.displayName) {
      alert('Имя и фамилия являются обязательным полем');
      return;
    }

    const { _id } = user;
    let updatedWithTopics = Object.assign({}, state, { topics }, { avatar: images });

    if (user.type === 'student') {
      if (!images.length) {
        alert('Выберите аватар');
        return;
      }

      if (!topics.length) {
        alert('Выберите интересующие темы уроков');
        return;
      }

      if (!state.telephone) {
        alert('Введите номер телефона');
        return;
      }

      if (!state.education) {
        alert('Укажите имя ученика');
        return;
      }

      if (!state.bio) {
        alert('Укажите возраст ученика');
        return;
      }

      if (!state.city) {
        alert('Укажите город вашего проживания');
        return;
      }

      if (!state.country) {
        alert('Укажите вашу страну проживания');
        return;
      }
    } else if (user.type === 'teacher') {
      if (!images.length) {
        alert('Выберите аватар');
        return;
      }

      if (!topics.length) {
        alert('Выберите темы уроков');
        return;
      }

      if (!state.telephone) {
        alert('Введите номер телефона');
        return;
      }

      if (!state.education) {
        alert('Введите ваше образование');
        return;
      }

      if (!state.bio) {
        alert('Заполните информацию о себе');
        return;
      }

      if (!state.city) {
        alert('Укажите город вашего проживания');
        return;
      }

      if (!state.country) {
        alert('Укажите вашу страну проживания');
        return;
      }

      if (!state.hasOwnProperty('video') || !state.video) {
        alert('Загрузите вашу видео визитку');
        return;
      }

      if (user.isApproved === 'new') {
        updatedWithTopics = Object.assign({}, updatedWithTopics, { isApproved: 'pending' });
      }
    }

    try {
      setIsLoading(true);
      await axios.put(`${API_URL}/users/${_id}`, updatedWithTopics);
      await getUserProfileInfo();
    } catch (err) {
      console.log('Update user error', err)
    } finally {
      setIsLoading(false);
    }
  };

  const onUploadInputTrigger = () => {
    if (state.video) {
      alert('Вы можете добавить только одно видео');
      return
    }
    document.getElementById('fake-file-video-input').click();
  };

  const handleChangeSelect = (event) => {
    setTopics(event.target.value);
  };

  const updateImagesArr = (img) => {
    setImages(img);
  };

  const deleteVideo = async (video) => {
    const imageFireId = video?.url?.split('/')[9]?.split('_')[0];
    const imageFireName = `${imageFireId}_${video.name}`;

    try {
      setIsLoading(true);
      await axios.delete(`${API_URL}/images/${imageFireName}`);
      onChangeUser({
        field: 'video',
        value: null
      })
    } catch (err) {
      console.log('Delete video error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteProfile = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`${API_URL}/users/${_id}`);
      await firebase.auth().currentUser.delete();
      localStorage.clear();
      history.push('/home');
    } catch (err) {
      console.log('Delete user profile error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserProfileInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className="teacher-profile-container">
      {user?.isApproved === 'new' && (
        <p>Чтобы иметь возможность создавать уроки, вы должны подать заявку на регистрацию учителя. Для этого заполните форму приведенную ниже. Не забудьте указать как можно больше подробностей - чем больше мы знаем о вас, тем легче мы сможем определить, подходите ли мы друг другу. Обязательно приложите все усилия и проверьте свою заявку на орфографические и грамматические ошибки. Вам также нужно будет записать короткую видео-визитку (3-5 минут), чтобы представиться, рассказать об уроках, которые вы будете преподавать и продемонстрировать свое учебное пространство. В дальнейшем эту визитку будут видеть и ученики и на основе ее (в том числе) принимать решение о покупке ваших уроков. Удачи!</p>
      )}

      {(user?.isApproved === 'pending') && (
        <p>Ваша заявка подана, ожидайте письмо на электронную почту.</p>
      )}

      <h3>Ваш аватар:</h3>
      <UploadMedia images={images} setImages={updateImagesArr} delete/>
      {user?.type === 'teacher' && (
        <div className="file-upload">
          <h3>Видео визитка:</h3>

        <div className='video-section-wrapper'>
          {isVideoLoading ?
            <div className="video-loader">
              <LoaderComponent />
            </div> :
            (state.video && (
                <div className="video-wrapper">
                  <div>
                    <img
                      src={require('../../assets/img/wrong.svg')}
                      className="card-delete"
                      onClick={() => deleteVideo(state.video)}
                      alt="delete"/>
                    <video poster={user.avatar[0].url} src={state.video.url} controls className="video"/>
                  </div>
                </div>
              )
          )}
          <div className="upload-video-field" onClick={onUploadInputTrigger}>
            <img src={require('../../assets/img/upload.svg')} alt="upload" className={'upload-img'}/>
          </div>
          <input type="file" accept="video/mp4" style={{ display: 'none' }} id='fake-file-video-input' ref={el} onChange={handleChange} />
        </div>
        </div>

      )}
      <TextField
          onChange={(e) => {
            onChangeUser({
              field: 'displayName',
              value: e.target.value
            })
          }}
          value={state.displayName}
          label="Имя"
      />
      <TextField
          onChange={(e) => {
            onChangeUser({
              field: 'email',
              value: e.target.value
            })
          }}
          disabled
          label="E-mail"
          value={state.email}
      />
      <TextField
          onChange={(e) => {
            onChangeUser({
              field: 'telephone',
              value: e.target.value
            })
          }}
          label="Телефон"
          value={state.telephone}
      />
      <TextField
          onChange={(e) => {
            onChangeUser({
              field: 'country',
              value: e.target.value
            })
          }}
          label="Страна"
          value={state.country}
      />
      <TextField
          onChange={(e) => {
            onChangeUser({
              field: 'city',
              value: e.target.value
            })
          }}
          label="Город"
          value={state.city}
      />
      <FormControl>
        <InputLabel id="demo-mutiple-checkbox-label">{(user.type === 'teacher') ? 'Темы уроков': 'Интересующие темы уроков'}</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={topics}
          onChange={handleChangeSelect}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
        >
          {TOPICS.map((item, i) => (
            <MenuItem key={item.value} value={item.text}>
              <Checkbox checked={topics.indexOf(item.text) > -1} />
              <ListItemText primary={item.text} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        onChange={(e) => {
          onChangeUser({
            field: 'education',
            value: e.target.value
          })
        }}
        label={(user.type === 'teacher') ? 'Образование': 'Имя ученика'}
        value={state.education}
      />
      <TextField
        onChange={(e) => {
          onChangeUser({
            field: 'bio',
            value: e.target.value
          })
        }}
        multiline={true}
        label={(user.type === 'teacher') ? 'Обо мне' : 'Возраст ученика'}
        value={state.bio}
      />

      {((user?.isApproved === 'approved' && user?.type === 'teacher') || user?.type === 'student') && (
        <Button variant="outlined" className="save-info" onClick={updateUser}>Сохранить</Button>
      )}

      {(user.isApproved === 'new' || user.isApproved === 'pending') && (
      < Button variant="outlined" className="save-info" onClick={updateUser}>Подать заявку</Button>
      )}

      {/*<Button variant="outlined" className="save-info" onClick={deleteProfile}>Удалить профиль</Button>*/}
    </div>
  );
};
