import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../constants';
import { LoaderComponent } from '../loader';
import { trimDate } from '../../utils';
import { Box } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';

import './styles.scss'

export const ReviewsList = ({ isRating, currentUserId }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [userReviewsData, setUserReviews] = useState(false);
  const [averageRating, setAverageRating] = useState(0);
  const userId = history.location.pathname.split('/')[2];
  const userIdValue = currentUserId ? currentUserId : userId;

  useEffect(() => {
    let cleanupFunction = false;

    const getUserAverageRating = async () => {
      try {
        const userInfo = await axios.get(`${API_URL}/users/${userIdValue}`);
        const averageRating = userInfo.data.rating;
        let avr = 0;
        for (const rate of averageRating) {
          avr = avr + +rate.value;
        }
        if (!cleanupFunction) {
          setAverageRating(avr / averageRating.length);
        }
      } catch (err) {
        console.log('Ger average rating error:', err)
      }
    };

    if (!userReviewsData) {
      const getUserReviews = async () => {
        try {
          const result = await axios.get(`${API_URL}/user-reviews/${userIdValue}`);
          const sortedData = result.data.slice().sort(function(x, y){
            return new Date(y.timestamp) - new Date(x.timestamp);
          });

          if (!cleanupFunction) {
            await getUserAverageRating();
            setUserReviews(sortedData);
          }

        } catch (err) {
          console.log('Get user review error', err)
        } finally {
          if (!cleanupFunction) {
            setIsLoading(false);
          }
        }
      };

      getUserReviews();

    }

    return () => cleanupFunction = true;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className="reviews-list-container">
      {isRating && (
        <div>
          <h3>Ваша средняя оценка: </h3>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <Rating
              disabled={true}
              name="simple-controlled"
              value={averageRating ? averageRating.toFixed(0) : 0}
            />
          </Box>
        </div>
        )}
      {userReviewsData && userReviewsData.map((item, index) => {
        return (
          <div key={index} className="comment-item">
            <img src={item.avatar[0].url} alt="avatar"/>
            <div className="comment-item-user-info">
              <p className="user-name">
                {item.displayName}
                <span className="comment-date">{trimDate(item.timestamp)}</span>
              </p>
              <p>{item.text}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
};
