import React from 'react';

import './styles.scss';

export const PrivacyPolicy = () => {
  return (
    <div className="info-container">
      <p>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</p>
      <p>В данной политике конфиденциальности (далее — «Политика») описано какую информацию Компания собирает о вас и зачем, как Компания использует собранную информацию и в каких случаях ее раскрывает.</p>
      <p>1. ОБЩИЕ ПОЛОЖЕНИЯ</p>
      <p>1.1. Используя наш Сайт, вы принимаете эту Политику и даете согласие на сбор, хранение использование своих персональных данных.</p>
      <p>1.2. Все использованные в настоящей Политике термины имеют такое же значение, как в условиях пользования Сайтом. Настоящая Политика является приложением к условиям пользования.</p>
      <p>2. КАКУЮ ИНФОРМАЦИЮ МЫ СОБИРАЕМ?</p>
      <p>2.1. Прежде всего, вы можете посещать общедоступные разделы Сайта анонимно, не раскрывая своё имя или любую другую информацию.</p>
      <p>2.2. Компания собирает и использует только ту информацию, которую вы указали во время регистрации.</p>
      <p>3. КАК МЫ ИСПОЛЬЗУЕМ ВАШУ ИНФОРМАЦИЮ?</p>
      <p>3.1. Ваша информация, используется Компанией для:<br />
        • предоставления услуг, предлагаемых на Сайте;<br />
        • облегчения пользования Сайтом и улучшения качества работы Сайта;<br />
        • информирования о важных новостях, услугах и рекламных предложениях Сайта.</p>
      <p>3.2. Компания может время от времени присылать на электронную почту Пользователей письма о новостях Компании или другие объявления о ее деятельности. Пользователь может отказаться от такой рассылки по электронной почте, нажав ссылку отказа от подписки в нижней части сообщения.</p>
      <p>4. РАСКРЫВАЕМ ЛИ МЫ ВАШУ ИНФОРМАЦИЮ?</p>
      <p>4.1. Компания не передает и не раскрывает персональные данные Пользователей третьим лицам за исключением случаев, когда:<br />
        • Компания получила согласие Пользователя на такое раскрытие;<br />
        • такие действия являются обязательными согласно действующему законодательству Украины;<br />
        • есть достаточные основания полагать, что раскрытие такой информации необходимо для соблюдения действующего законодательства Украины, требований юридического процесса или законного запроса государственных органов.</p>
      <p>5. ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ</p>
      <p>5.1. Информация Пользователей хранится на наших защищенных серверах. Вне зависимости от расположения серверов Компания принимает все необходимые меры для защиты персональных данных Пользователей от неавторизованного доступа, изменения, раскрытия или уничтожения.</p>
      <p>5.2. В то же время, Компания обращает внимание на то, что ни один из существующих способов передачи данных не может быть абсолютно безопасным. Поэтому Компания, не смотря на все принятые меры по обеспечению безопасности, не может гарантировать полную сохранность данных.</p>
      <p>6. ОТВЕТСТВЕННОСТЬ КОМПАНИИ</p>
      <p>6.1. Компания несет ответственность за все действия или бездействие касательно персональных данных Пользователей в рамках применимого законодательства.</p>
      <p>6.2. Компания не несет ответственность за любые действия Пользователей Сайта и третьих лиц с персональными данными Пользователей независимо от того, получены ли эти данные через Сайт или другим способом.</p>
      <p>7. ИСПОЛЬЗУЕМ ЛИ МЫ COOKIES?</p>
      <p>7.1. Да. Компания использует cookie-файлы, веб-маяки и другие подобные технологии для сбора сведений о просматриваемых страницах, переходах по ссылкам, а также о других ваших действиях, связанных с посещением и использованием Сайта.</p>
      <p>7.2. Файл cookie – это файл небольшого размера, обычно состоящий из букв и цифр, который загружается на устройство, когда Пользователь посещает определенные веб-сайты и помогает сайту распознавать веб-браузер или устройство Пользователя при повторных посещениях. Компания использует файлы cookie в целях:<br />
        • технические cookie-файлы необходимы Сайту для безошибочной работы;<br />
        • функциональные cookie-файлы необходимы, чтобы запоминать предпочтения Пользователя, в частности, предпочитаемую валюту и язык, историю поиска и туристические услуги, которые Пользователь просматривал ранее. Такие cookie-файлы необходимы, чтобы сделать Сайт более удобным в использовании;<br />
        • аналитические cookie-файлы необходимы, чтобы узнать, как Пользователи используют Сайт, в частности, какие страницы посетил Пользователь, с каких страниц перешел и на какие, с каких страниц Пользователь покинул Сайт, количество кликов мышкой на определенной странице, поисковые запросы Пользователя. Такие cookie-файлы необходимы, чтобы оптимизировать Сайт и улучшить его работу;<br />
        • коммерческие cookie-файлы необходимы, чтобы показывать Пользователю рекламные объявления Компании на других сайтах. То есть Пользователю будут показываются объявления о направлениях, которые он просматривал раннее, а также о просмотренных ранее объектах размещения.</p>
      <p>7.3. Вы можете заблокировать, отключить и удалить cookie-файлы, веб-маяки и другие аналогичные элементы, если ваш веб-браузер или устройство позволяют сделать это.</p>
      <p>8. ССЫЛКИ НА ВНЕШНИЕ САЙТЫ</p>
      <p>8.1. Сайт может содержать ссылки на посторонние сайты. Компания не несет ответственность за содержание и деятельность таких сайтов, а также за обеспечение ими вашей конфиденциальности.</p>
      <p>9. ИЗМЕНЕНИЯ К ПОЛИТИКЕ</p>
      <p>9.1. Компания может обновлять, редактировать и вносить изменения в настоящую Политику без дополнительного уведомления. Такие изменения публикуются на сайте Сайта и вступают в силу сразу после публикации, если иное не указано в самих изменениях.</p>
      <p>Если, по вашему мнению, условия настоящей Политики были нарушены, или если у вас есть вопросы касательно конфиденциальности вашей информации, напишите нам по адресу support@lessonslab.com</p>
    </div>
  );
};
