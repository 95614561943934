import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { UploadMedia } from '../uploadMedia';
import axios from 'axios';
import { DateTimePicker } from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { API_URL, TOPICS } from '../constants';
import { LoaderComponent } from '../loader';
import { BankInfoModal } from '../bankInfoModal';

import './styles.scss';

const LESSON_DURATION = [
  {
    text: '30 мин',
    value: 30
  },
  {
    text: '45 мин',
    value: 45
  },
  {
    text: '1 час',
    value: 60
  },
  {
    text: '1 час 15 мин',
    value: 75
  },
  {
    text: '1 час 30 мин',
    value: 90
  },
  {
    text: '1 час 45 мин',
    value: 105
  },
  {
    text: '2 час',
    value: 120
  }
];

// const LESSON_CREATE_AVAILABLE = 86400000 * 7; // 7 days
const LESSON_CREATE_AVAILABLE = 3600000 * 7; // 7 hours

const initialValues = {
  ageGroupFrom: '',
  ageGroupTo: '',
  congratsMessage: "",
  desc: "",
  startTs: null,
  createTs: null,
  duration: "",
  lessonFullDesc: "",
  lessonItems: "",
  lessonRecurrence: "",
  lessonSizeFrom: 1,
  lessonSizeTo: 1,
  name: "",
  price: "0",
  theme: "",
  followers: []
};

export const CreateLesson = ({ setTabValue }) => {
  const history = useHistory();
  const [lesson, setLessonData] = useState(initialValues);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [groupSize, setGroupSize] = useState( true);
  const [lessonSize, setLessonSize] = useState(true);
  const [priceSize, setPriceSize] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLessonCloned, setIsLessonCloned] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));

  const onChangeLessonData = ({ field, value }) => {
    if (field === 'groupSize' && value === 'all') {
      setGroupSize(true);
      setLessonData({...lesson, ageGroupFrom: '', ageGroupTo: ''});
      return;
    } else if (field === 'groupSize' && value === 'sized') {
      setGroupSize(false);
      setLessonData({...lesson, ageGroupFrom: '', ageGroupTo: ''});
      return;
    }

    if (field === 'lessonSize' && value === 'all') {
      setLessonSize(true);
      setLessonData({ ...lesson, lessonSizeFrom: 1, lessonSizeTo: 1 });
      return;
    }

    if (field === 'lessonSize' && value === 'sized') {
      lesson.lessonSizeFrom = 1;
      lesson.lessonSizeTo = 5;
      setLessonSize(false);
    }

    if (field === 'priceSize' && value === 'free') {
      setPriceSize(true);
      setLessonData({...lesson, price: '0'});
      return;
    } else if (field === 'priceSize' && value === 'payed') {
      setPriceSize(false);
    }

    setLessonData({ ...lesson, [field]: value });
  };

  const createLesson = async () => {
    if (!lesson.name) {
      alert('Введите название урока');
      return;
    }

    if (!lesson.desc) {
      alert('Введите краткое описание урока');
      return;
    }

    if (!lesson.theme) {
      alert('Выбирите тему урока');
      return;
    }

    if (!images.length > 0) {
      alert('Выбирите картинку вашего урока');
      return;
    }

    if (
      (lesson.ageGroupFrom && lesson.ageGroupTo &&
        parseInt(lesson.ageGroupFrom, 10) > parseInt(lesson.ageGroupTo, 10)) ||
      (lesson.ageGroupFrom < 0 || lesson.ageGroupTo < 0) ||
      (!groupSize && (lesson.ageGroupFrom === '' || lesson.ageGroupTo === ''))
    ) {
      alert('Введите корректную возрастную группу');
      return;
    }

    if (lesson.ageGroupTo > 18) {
      alert('Выберите возрастную группу ниже 18-ти лет');
      return;
    }

    if (
      (lesson.lessonSizeFrom &&  lesson.lessonSizeTo &&
        parseInt(lesson.lessonSizeFrom, 10) > parseInt(lesson.lessonSizeTo, 10)) ||
      (lesson.lessonSizeTo <= 0 || lesson.lessonSizeFrom <= 0)
    ) {
      alert('Введите корректный размер класса');
      return;
    }

    if (!lesson.startTs) {
      alert('Выберите дату и время урока');
      return;
    }

    if (!lesson.duration) {
      alert('Выберите продолжительность урока');
      return;
    }

    if (!lesson.lessonFullDesc) {
      alert('Напишите подробное описание вашего урока');
      return;
    }

    if (!lesson.congratsMessage) {
      alert('Напишите приветствие подписавшихся учеников для вашего урока');
      return;
    }

    const utcDate = new Date(lesson.startTs);

    if (+utcDate <= +new Date()) {
      alert('Выберите корректную дату и время урока');
      return;
    }

    if ((+new Date() + LESSON_CREATE_AVAILABLE) >= +new Date(lesson.startTs)) {
      alert('Вы можете создать урок минимум за 7 часов до его начала');
      return;
    }

    if (lesson.price < 0) {
      alert('Введите корректную цену урока');
      return;
    }

    if (lesson.price > 0 && !user.bankAccountInfo) {
      alert('Добавьте банковскую карту что бы иметь возможность создавать платные уроки');
      return;
    }

    if (lesson.price >= 1 && lesson.price < 7) {
      alert('Минимальная цена урока 7$');
      return;
    }

      try {
        setIsLoading(true);
        const userName = user.displayName.split(' ');
        const createZoomUserPayload = {
          action: 'create',
          user_info: {
            email: user.email,
            type: 1,
            first_name: userName[0],
            last_name: userName[1]
          }
        };

        const createScheduleMeetingPayload = {
          topic: lesson.name,
          type: 2, // Change type for recurring meetings
          start_time: lesson.startTs,
          duration: lesson.duration,
          password: 'Hey123',
          agenda: lesson.desc,
          settings: {
            host_video: false,
            participant_video: false,
            join_before_host: false,
            mute_upon_entry: true,
            use_pmi: false,
            approval_type: 0
          }
        };

        await axios.post(`${API_URL}/create-user`, createZoomUserPayload);
        const scheduleMeeting = await axios.post(`${API_URL}/meeting/${user.email}`, createScheduleMeetingPayload);

        if (scheduleMeeting.data.code === 1001) {
          alert('Пожалуйста подтвердите аккаунт на вашей електронной почте которая привязана к этому аккаунту');
          return;
        } else {
          const meetingInfo = {
            event: {
              startUrl: scheduleMeeting.data.start_url,
              joinUrl: scheduleMeeting.data.join_url,
              meetingId: scheduleMeeting.data.id
            },
            userRole: 1
          };

          const fullClubInfo = Object.assign(
            {},
            lesson,
            meetingInfo,
            {
              images,
              host: user._id,
              startTs: utcDate,
              createTs: new Date()
              });
          delete fullClubInfo._id;
          const createdLesson = await axios.post(`${API_URL}/lessons/${user._id}`, fullClubInfo);
          history.push(`/lesson/${createdLesson.data._id}`);
        }
    } catch (err) {
        if (+err.message.split('code')[1] === 400) {
        alert('В это время у Вас будет другой урок');
      }
        console.log('Create lesson error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateImagesArr = (img) => {
    setImages(img)
  };

  const onModalCLose = () => {
    setIsModalOpen(false);
  };

  const getPriceDefaultValue = () => {
    const prevLessonPrice = history?.location?.state?.lessonData?.lesson?.price || lesson?.price;
    if (!prevLessonPrice) {
      return 'free';
    }
    return parseInt(prevLessonPrice, 10) === 0 ? 'free' : 'payed';
  };

  const getAgeDefaultValue = () => {
    const prevLessonAgeGroupFrom = history?.location?.state?.lessonData?.lesson?.ageGroupFrom || lesson?.ageGroupFrom;
    const prevLessonAgeGroupTo = history?.location?.state?.lessonData?.lesson?.ageGroupTo || lesson?.ageGroupTo;
    if (!prevLessonAgeGroupFrom && !prevLessonAgeGroupTo){
      return 'all';
    }
    return 'sized';
  }

  const getLessonSizeDefaultValue = () => {
    const prevLessonSizeFrom = history?.location?.state?.lessonData?.lesson?.lessonSizeFrom || lesson?.lessonSizeFrom;
    const prevLessonSizeTo = history?.location?.state?.lessonData?.lesson?.lessonSizeTo || lesson?.lessonSizeTo;
    if (!prevLessonSizeFrom && !prevLessonSizeTo){
      return 'all';
    }
    return (parseInt(prevLessonSizeFrom, 10) === 1 && parseInt(prevLessonSizeTo, 10) === 1) ? 'all' : 'sized';
  }

  useEffect(() => {
    if (
      history.location.state &&
      history.location.state.hasOwnProperty('lessonData')
      && history.location.state.lessonData.lesson
    ) {
      setIsLessonCloned(true);
      setImages(history.location.state.lessonData.lesson.images)
      history.location.state.lessonData.lesson.followers = [];
      history.location.state.lessonData.lesson.comments = [];
      history.location.state.lessonData.lesson.rating = [];
      history.location.state.lessonData.lesson.ik_ps_price = '';
      setLessonData(history.location.state.lessonData.lesson);
      if (getPriceDefaultValue() === 'payed') {
        setPriceSize(false);
      }
      if (getAgeDefaultValue() === 'sized') {
        setGroupSize(false);
      }
      if (getLessonSizeDefaultValue() === 'sized') {
        setLessonSize(false);
      }
    }

    history.replace('/profile', {});
  }, [history, history.location.state]);

  if (isLoading) {
    return <LoaderComponent />;
  }

  return (
    <div className='create-lesson-container'>
      <div className='sub-container'>
        <h3>Название</h3>
        <p>Выбертите громкое и цепляющее название вашему уроку, отражающее его суть</p>
        <TextField
          value={lesson.name}
          onChange={(e) => {
            onChangeLessonData({
              field: 'name',
              value: e.target.value
            })
          }}
          label="Название"
        />
      </div>

      <div className='sub-container'>
        <h3>Краткое описание урока</h3>
        <p>Напишите пару предложений описывающих ваш урок.</p>
        <TextField
          value={lesson.desc}
          onChange={(e) => {
            onChangeLessonData({
              field: 'desc',
              value: e.target.value
            })
          }}
          label="Краткое описание урока"
        />
      </div>

      <div className='sub-container'>
        <h3>Тема</h3>
        <p>Выберите тему, хоторая наиболее точно подходит вашему уроку</p>
        <TextField
          value={lesson.theme}
          onChange={(e) => {
            onChangeLessonData({
              field: 'theme',
              value: e.target.value
            })
          }}
          select
        >
          {TOPICS.map((item, i) => {
            return (
              <MenuItem value={item.text} key={i}>{item.text}</MenuItem>
            )
          })
          }
        </TextField>
      </div>

      <div className='sub-container'>
        <h3>Аватарка урока</h3>
        <p>Загрузите качественную привлекающую внимание картинку, которая отображает суть вашего урока. Величина картинки не должна быть менее 600 пикселей в ширину и не более 5Мб величиной. </p>
        <UploadMedia images={images} setImages={updateImagesArr} delete isLessonCloned={isLessonCloned} isLessonAvatar={true}/>
      </div>

      <div className='sub-container'>
        <h3>Возрастная группа</h3>
        <p>На детей какого возраста расчитан ваш урок? Выберите возрастную вилку (например, 3-4 года или 7-10 лет)</p>
        <div className="range-select-container">
          <RadioGroup
            value={lesson.groupSize}
            defaultValue={getAgeDefaultValue}
            onChange={(e) => {
              onChangeLessonData({
                field: 'groupSize',
                value: e.target.value
              })
            }}
          >
            <FormControlLabel value="all" control={<Radio />} label="Все возраста" />
            <FormControlLabel value="sized" control={<Radio />} label={
              <div className="radio-controls-wrapper">

                от

                <TextField
                  value={lesson.ageGroupFrom}
                  disabled={groupSize}
                  onChange={(e) => {
                    onChangeLessonData({
                      field: 'ageGroupFrom',
                      value: e.target.value
                    })
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  label=""
                  type='number'
                />

                до

                <TextField
                  value={lesson.ageGroupTo}
                  disabled={groupSize}
                  onChange={(e) => {
                    onChangeLessonData({
                      field: 'ageGroupTo',
                      value: e.target.value
                    })
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  label=""
                  type='number'
                />
              </div>
            }/>
          </RadioGroup>
        </div>
      </div>

      <div className='sub-container'>
        <h3>Размер класса</h3>
        <p>Напишите какое количество детей одновременно вы готовы обучать на этом уроке? Мы рекомендуем не большие классы, особенно для младших детей (3-4 человека для деток 4-5 лет) и до 10 человек для подростков старше 12 лет</p>
        <div className="range-select-container">
          <RadioGroup
            value={lesson.lessonSize}
            defaultValue={getLessonSizeDefaultValue}
            onChange={(e) => {
              onChangeLessonData({
                field: 'lessonSize',
                value: e.target.value
              })
            }}
          >
            <FormControlLabel value="all" control={<Radio />} label="Индивидуальный урок" />
            <FormControlLabel value="sized" control={<Radio />} label={
              <div className="radio-controls-wrapper">

                от

                <TextField
                  value={lesson.lessonSizeFrom}
                  disabled={lessonSize}
                  onChange={(e) => {
                    onChangeLessonData({
                      field: 'lessonSizeFrom',
                      value: e.target.value
                    })
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  label=""
                  type='number'
                />

                до

                <TextField
                  value={lesson.lessonSizeTo}
                  disabled={lessonSize}
                  onChange={(e) => {
                    onChangeLessonData({
                      field: 'lessonSizeTo',
                      value: e.target.value
                    })
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  label=""
                  type='number'
                />
              </div>
            }/>
          </RadioGroup>
        </div>
      </div>

      <div className='sub-container'>
        <h3>Дата и время урока</h3>
        <div className="range-select-container date-time-input">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker
              variant="inline"
              autoOk
              disablePast
              ampm={false}
              value={lesson && lesson.startTs && moment(lesson.startTs).utc().format()}
              onChange={(e) => {
                onChangeLessonData({
                  field: 'startTs',
                  value: moment(e).utc().format()
                })
              }}
              format="YYYY.MM.DD HH:mm"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <EventIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </MuiPickersUtilsProvider>

          <p className="date-timezone">
            {new Date().toTimeString().split(' ')[1]}
          </p>
        </div>
      </div>

      <div className='sub-container'>
        <h3>Длительность урока</h3>
        <div className="small-field-select">
          <TextField
            value={lesson.duration}
            onChange={(e) => {
              onChangeLessonData({
                field: 'duration',
                value: e.target.value
              })
            }}
            select
          >
            {LESSON_DURATION.map((item, i) => {
              return (
                <MenuItem value={item.value} key={i}>{item.text}</MenuItem>
              )
            })
            }
          </TextField>
        </div>
      </div>

      <div className='sub-container'>
        <h3>Подробное описание урока/курса</h3>
        <p>Описывая ваш урок, попробуйте ответить на такие вопросы:<br/>
        Что вы будете изучать, какие темы будут охвачены?<br/>
        Как именно вы будете взаимодействовать с учениками (слайды, игры, беседы, или же это будет лекция)<br/>
        Нужны ли ученикам какие-то базовые знания, предварительные умения?<br/>
        Будет ли домашнее задание, какое?<br/>
        Нужен ли присмотр/помощь родителей (особенно это касается уроков для маленьких детей)<br/>
        Ну и наконец, кикими знаниями и умениями будут обладать ученики после прохождения вашего урока?<br/>
          
          </p>
        <TextField
          value={lesson.lessonFullDesc}
          onChange={(e) => {
            onChangeLessonData({
              field: 'lessonFullDesc',
              value: e.target.value
            })
          }}
          label="Подробное описание урока/курса"
          multiline
        />
      </div>

      <div className='sub-container'>
        <h3>Список материалов, которые понадобятся на уроке</h3>
        <p>Например, если это урок рисования, список будет таким: бумага, кисточка, краски</p>
        <TextField
          value={lesson.lessonItems}
          onChange={(e) => {
            onChangeLessonData({
              field: 'lessonItems',
              value: e.target.value
            })
          }}
          label="Список материалов"
        />
      </div>

      <div className='sub-container'>
        <h3>Приветствие подписавшихся учеников</h3>
        <p>Это сообщение получат все подписавшиеся на урок ученики. Здесь Вы можете их просто поприветствовать, попросить рассказать перед уроком пару слов о себе, напомнить подготовить необходимые материалы, если такие имеются, или же распечатать присоединенный вами файл.</p>
        <TextField
          value={lesson.congratsMessage}
          onChange={(e) => {
            onChangeLessonData({
              field: 'congratsMessage',
              value: e.target.value
            })
          }}
          label="Приветствие"
          multiline
        />
      </div>

      <div className='sub-container'>
        <h3>Цена</h3>
        <p>Вы сами устанавливаете цены на ваши уроки. Цена зависит от количества человек в группе (индивидуальные занятия всегда дороже), а также от востребованности темы занятия. Обычно цены на урок варьируются в пределах $7-25 с человека за час занятия</p>
        <div className="range-select-container">
          <RadioGroup
            value={lesson.priceSize}
            defaultValue={getPriceDefaultValue()}
            onChange={(e) => {
              onChangeLessonData({
                field: 'priceSize',
                value: e.target.value
              })
            }}
          >
            <FormControlLabel value="free" control={<Radio />} label="Бесплатный урок" />
            <FormControlLabel value="payed" control={<Radio />} label={
              <div className="radio-controls-wrapper">
                <TextField
                  value={lesson.price}
                  disabled={priceSize}
                  onChange={(e) => {
                    onChangeLessonData({
                      field: 'price',
                      value: e.target.value
                    })
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  label=""
                  type='number'
                />

                $ c ученика
              </div>
            }/>
          </RadioGroup>
        </div>
      </div>

      <div className='sub-container'>
        <h3>Политика возврата денег</h3>
        <p>У нас действует следующая политика возврата денег ученикам: полный возврат при отмене бронирования за 5 дней до начала занятия, при неявке ученика, деньги все равно списываются с его счета и поступят на ваш.<br/>
Деньги поступают на ваш счет в течение 3х рабочих дней</p>
      </div>

      {lesson.price > 0 && !user.bankAccountInfo && (
        <div className='sub-container' onClick={() => setIsModalOpen(true)}>
          <Button variant="outlined">Добавить банковский аккаунт</Button>
        </div>
      )}

      <BankInfoModal isModalOpen={isModalOpen} onModalCLose={onModalCLose}/>

      <Button variant="outlined" onClick={createLesson}>Создать урок</Button>
    </div>
  );
};
