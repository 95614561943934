import React, { useEffect, useState } from 'react';
import { LessonsList } from '../lessonsList';
import axios from 'axios';
import { API_URL } from '../constants';
import { LoaderComponent } from '../loader';
import { Button, TextField } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { FilterModal } from '../filterModal';

import './styles.scss';

export const SubscribedLessons = () => {
  const [lessons, setLessons] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const onSearch = event => {
    const searchTerm = event.target.value;
    const results = [...lessons].filter(lesson => {
      return lesson.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
    });

    setSearchResults(results);
  };

  const getUserEvents = async () => {
    try {
      setIsLoading(true);
      let lessonsData = await axios.post(`${API_URL}/lessons-follow`, { follow: user.follow });
      lessonsData = await lessonsData.data.slice().sort((a, b) => {
        const dateOne = new Date(a.startTs);
        const dateTwo = new Date(b.startTs);
        return dateOne - dateTwo;
      });
      setLessons(lessonsData);
    } catch (err) {
      console.log('Get user events error', err);
    } finally {
      setIsLoading(false);
    }
  };

  const applyStudentFilter = (filterData) => {
    let res;
    if (!filterData) {
      setSearchResults(lessons);
      return;
    }

    if (user && user.type === 'student') {
      if (filterData && filterData.includes('archivedLessons') && filterData.length === 1 ) {
        res = lessons.filter((item) => {
          return +new Date(item.startTs) <= +new Date();
        })
      } else if (filterData.includes('nearestLessons') && filterData.length === 1 ) {
        res = lessons.filter((item) => {
          return +new Date(item.startTs) > +new Date();
        })
      } else if (filterData.includes('archivedLessons') && filterData.includes('nearestLessons')){
        res = lessons;
      }
      setSearchResults(res);
    }
  };

  useEffect(() => {
    getUserEvents();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <LoaderComponent />
  }

  return (
    <div className="lessons-wrapper">
      <div className="search-filter-wrapper">
        <TextField
          label="Найти урок"
          type='search'
          className='search-input'
          onChange={(e) => onSearch(e)}
        />

        <Button variant="outlined" onClick={() => setModalOpen(true)}>
          <FilterListIcon />
        </Button>
      </div>

      <FilterModal
        isModalOpen={isModalOpen}
        setModalOpen={setModalOpen}
        applyFilterChanges={applyStudentFilter}
        personalSort
      />
        <LessonsList loading={setIsLoading} lessonsDate={searchResults ? searchResults : lessons}/>
    </div>
  );
};
