import React from 'react';

import './styles.scss';

export const Footer = () => {

  return (
    <div className="footer-container">
      <div className="top-block">
        <p className="text-info">
          Если у вас возникли вопросы по оплате, свяжитесь со службой поддержки:<br /> тел.: <a href="tel:+38(063)3292165">+38(063)3292165</a>, <br />e-mail: <a href="mailto:support@lessonslab.com">support@lessonslab.com</a>
        </p>
        <p className="text-info">
          Администрация сайта:<br /> тел.: <a href="tel:+1 (604) 445-3511">+1 (604) 445-3511</a><br /> e-mail: <a href="mailto:telysyuk@lessonslab.com">telysyuk@lessonslab.com</a>
        </p>
        <p className="text-info">
          Ознакомиться с договором публичной оферты, а также узнать подробности об условиях доставки, возврата, узнать контакты продавца можно по ссылке: <a href="https://lessonslab.com/public-agreement" target="_blank" rel="noopener noreferrer">https://lessonslab.com/public-agreement</a>
        </p>
      </div>
        <a href="https://lessonslab.com/privacy-policy" target="_blank" rel="noopener noreferrer">Политика конфиденциальности и cookie</a>
    </div>
  );
};
