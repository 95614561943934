import React from 'react';
import { useHistory } from 'react-router-dom';
import { trimDate } from '../../utils';
import axios from 'axios';
import { API_URL } from '../constants';
import userAvatar from '../../assets/img/teacher_avatar_mok.png';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

import './styles.scss';

export const LessonsList = ({ lessonsDate, loading, isFavoriteComponent, updateLessons }) => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('user'));

  const goToLesson = async (el) => {
    history.push(`/lesson/${el._id}`)
  };

  const goToPerson = async (e, id) => {
    e.stopPropagation();
    history.push(`/teacher/${id}`)
  };

  const addToFavorites = async (lesson) => {
    let favoriteLessons = [...user.favorites];
    if (user.favorites.includes(lesson._id)) {
      favoriteLessons = favoriteLessons.filter((el) => {
        return el !== lesson._id;
      });
    } else {
      favoriteLessons.push(lesson._id)
    }

    try {
      loading(true);
      const res = await axios.put(`${API_URL}/users/${user._id}`, {
        favorites: favoriteLessons
      });
      localStorage.setItem('user', JSON.stringify(res.data));
      updateLessons(res.data.favorites);
    } catch (err) {
      console.log('User favorites update error:', err);
    } finally {
      loading(false);
    }
  };

  return (
    <div>
      {lessonsDate && lessonsDate.length ? lessonsDate.map((item, index) => {
        return (
          <div key={index} className="lesson-container">
            <div className="image-container" onClick={() => goToLesson(item)}>
              <img
                src={item?.images.length && item?.images[0].url ? item.images[0].url : userAvatar}
                alt='avatar' referrerPolicy="no-referrer"
                className="lesson-image"
              />
            </div>
            <div className="lesson-info">
              <div className="lesson-favourite-heading">
                <h2 onClick={() => goToLesson(item)}>{item?.name}</h2>
                {!isFavoriteComponent && user && user.type === 'student' && (
                    <div onClick={() => addToFavorites(item)}>
                      {user.favorites.includes(item?._id) ? (
                          <FavoriteIcon />
                      ): (
                          <FavoriteBorderIcon />
                      )}
                    </div>
                )}
              </div>

              <p className="desc-text teacher-name" onClick={(e) => goToPerson(e, item?.host?._id)}>{item?.host?.displayName}</p>
              <p className="desc-text">{item?.desc}</p>

              <div className="small-info">
                <p>{!item?.ageGroupFrom && !item?.ageGroupTo ?
                    'Все возраста' :
                    `${item.ageGroupFrom} - ${item.ageGroupTo} лет`
                }
                </p>

                <p>{item?.lessonSizeFrom === 1 && item?.lessonSizeTo === 1 ?
                    'Индивидуальный урок' :
                    `${item?.lessonSizeFrom} - ${item?.lessonSizeTo} чел`
                }
                </p>

                <p>{trimDate(item?.startTs)}</p>
                <p>{item?.price === 0 ? 'Бесплатно' : `${item?.price}$`}</p>
              </div>
            </div>
          </div>
        )
      }) : <p>Ничего не найдено</p>
      }
    </div>
  );
};
